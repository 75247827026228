const BASE_URL = "http://20.52.3.185:8021";
//const BASE_URL = "http://20.79.179.162:8021";
//const BASE_URL = "https://localhost:44352";

export const API_CONFIGURATIONS = {
    //Auth
    AUTH_USER: `${BASE_URL}/Login`,

    //Category
    CATEGORY: `${BASE_URL}/Category`,

    //End-User-Roles
    END_USER_ROLES: `${BASE_URL}/EndUserPermissionSet`,
    ASSIGNED_END_USER_ROLES: `${BASE_URL}/EndUserPermissionSet/AssignedUsers?role=`,

    //Fuctional Permission Sets
    FUNCTIONAL_PERMISSION_SETS: `${BASE_URL}/FunctionalPermissionSet`,
    ASSIGNED_FUNCTIONAL_PERMISSION_SETS: `${BASE_URL}/FunctionalPermissionSet/AssignEndUserRoles?role=`,

    //Function-End-User-Roles
    FUNCTION_TO_END_USER_ROLES: `${BASE_URL}/PermissionSetMapping`,

    //End-User-Fuction
    END_USER_TO_FUNCTIONAL_PERMISSION_SETS: `${BASE_URL}/EndUserToFuncMapping`,

    //Consolidations
    NAVIGATOR_CONSOLIDATIONS: `${BASE_URL}/PermissionSetMapping`,
    GRANTED_NAVIGATOR_CONSOLIDATIONS: `${BASE_URL}/PermissionSetMapping/GetGrantedPermissions`,

    //Navigator
    GET_PARENT_NAVIGATOR_TO_FUNCTIONAL_PERMISSION_SETS: `${BASE_URL}/EndUserToNavMapping?parent=`,
    UPDATE_NAVIGATOR_TO_FUNCTIONAL_PERMISSION_SETS: `${BASE_URL}/EndUserToNavMapping`,
    BULK_UPDATE_NAVIGATOR_TO_FUNCTIONAL_PERMISSION_SETS: `${BASE_URL}/EndUserToNavMapping/NavigatorEntries`,
    GET_PARENT_HIERARCHY_NAVIGATOR_TO_FUNCTIONAL_PERMISSION_SETS: `${BASE_URL}/EndUserToNavMapping/NavigatorHierarchy?parent=`,
    GET_PARENT_HIERARCHY_NAVIGATOR_TO_END_USER_ROLES: `${BASE_URL}/EndUserToNavMapping/NavigatorEndUserHierarchy`,
    GET_OMITED_HIERARCHY_NAVIGATOR_TO_END_USER_ROLES: `${BASE_URL}/EndUserToNavMapping/OmitedNavigatorEndUserHierarchy`,
    //Navigator
    GET_PARENT_NAVIGATOR_TO_FUNCTIONAL_PERMISSION_SETS: `${BASE_URL}/EndUserToNavMapping?parent=`,
    UPDATE_NAVIGATOR_TO_FUNCTIONAL_PERMISSION_SETS: `${BASE_URL}/EndUserToNavMapping`,
    BULK_UPDATE_NAVIGATOR_TO_FUNCTIONAL_PERMISSION_SETS: `${BASE_URL}/EndUserToNavMapping/NavigatorEntries`,
    GET_PARENT_HIERARCHY_NAVIGATOR_TO_FUNCTIONAL_PERMISSION_SETS: `${BASE_URL}/EndUserToNavMapping/NavigatorHierarchy?parent=`,
    GET_PARENT_HIERARCHY_NAVIGATOR_TO_END_USER_ROLES: `${BASE_URL}/EndUserToNavMapping/NavigatorEndUserHierarchy`,
    GET_OMITED_HIERARCHY_NAVIGATOR_TO_END_USER_ROLES: `${BASE_URL}/EndUserToNavMapping/OmitedNavigatorEndUserHierarchy`,
    GET_OMITED_HIERARCHY_NAVIGATOR_TO_FUNCTIONAL_PERMISSION_SETS: `${BASE_URL}/EndUserToNavMapping/OmitedNavigatorHierarchy`,

    //User-Group
    USER_GROUPS: `${BASE_URL}/UserGroup`,

    //User
    USER: `${BASE_URL}/User`,

    //User-End-User
    USER_TO_END_USER_ROLES: `${BASE_URL}/UserGrantMapping`,
};