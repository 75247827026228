import { Grid, Typography } from "@mui/material"
import { type } from "@testing-library/user-event/dist/type";
import TemplateTypes from "../../helpers/datasource/templateTypes";
import TemplateTypeCard from "../../components/TemplateTypeCard";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const Templates = () => {
    const location = useLocation();

     useEffect(() => {
       const currentPath = location.pathname;
       localStorage.setItem("previousPath", currentPath);
     }, [location.pathname]);

    const renderTemplateGrid = () => {
        const templateGrid = [];
        TemplateTypes.forEach(templateType => {
            templateGrid.push(<TemplateTypeCard templateType={templateType} />)
        })

        return templateGrid;
    }
    return (
        <Grid container spacing={4}>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "left" }}>
                <h2 className="page-title">Predefined Templates</h2>
            </Grid>
            {renderTemplateGrid()}
        </Grid>
    )
}

export default Templates;