import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    showStatus: false,
    seranity: '',
    title: '',
    message: ''
}

const actionStatusSlice = createSlice({
    name: 'actionStatus',
    initialState,
    reducers: {
        showActionStatus: (state, action) => {
            const {seranity, title, message} = action.payload;
            state.seranity= seranity;
            state.title = title;
            state.message = message;
            state.showStatus = true;
        },
        closeActionStatus: (state, action) => {
            console.log("Delete response runnig", state, action)
            state.showStatus= false;
            state.seranity= '';
            state.title= '';
            state.message= '';
        }
    }
})

export const {showActionStatus, closeActionStatus} = actionStatusSlice.actions;
export default actionStatusSlice.reducer;

export const selectActionStatus = state => state.actionStatus;