import { combineReducers, configureStore } from "@reduxjs/toolkit";
import endUserRolesReducer from "./reducers/endUserRoleSlice";
import functionalPermissionSetReducer from "./reducers/functionalPermissionSetSlice";
import functionalEndUserMappingReducer from "./reducers/FunctionalToEndUserMappingSlice";
import permissionSetCategoryReducer from "./reducers/permissionSetCategorySlice";
import functionalToEndUserMatrixSlice from "./reducers/functionalToEndUserMatrixSlice";
import usersToEndUserRoleSlice from "./reducers/usersToEndUserRoleSlice";
import navigatorToFunctionalPermissionSetsSlice from "./reducers/navigatorToFunctionalPermissionSetsSlice";
import userGroupSlice from "./reducers/userGroupSlice";
import navigatorToFunctionalPermissionSetsMultiSlice from "./reducers/navigatorToFunctionalPermissionSetsMultiSlice";
import userSlice from "./reducers/userSlice";
import authUserSlice from "./reducers/authUserSlice";
import actionStatusSlice from "./reducers/actionStatusSlice";
import navigatorConsolidationSlice from "./reducers/navigatorConsolidationSlice";

const appReducer = combineReducers({
  endUserRoles: endUserRolesReducer,
  functionalPermissionSets: functionalPermissionSetReducer,
  functionalToEndUserMapping: functionalEndUserMappingReducer,
  permissionSetCategories: permissionSetCategoryReducer,
  functionalToEndUserMatrix: functionalToEndUserMatrixSlice,
  usersToEndUserRole: usersToEndUserRoleSlice,
  navigatorToFunctionalPermissionSets: navigatorToFunctionalPermissionSetsSlice,
  userGroups: userGroupSlice,
  navigatorToFunctionalPermissionSetsMulti:navigatorToFunctionalPermissionSetsMultiSlice,
  users: userSlice,
  authUser: authUserSlice,
  actionStatus: actionStatusSlice,
  navigatorConsolidation: navigatorConsolidationSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    // Set all reducers' states to undefined to reset them
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;
