import { Stack, IconButton, Grid } from "@mui/material";
import { Delete, ContentCopy, ModeEdit } from '@mui/icons-material';
import DataTable from "./DataTable";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
    selectAllPermissionSetCategories,
    fetchPermissionSetCategories,
    deletePermissionSetCategory
} from "../../../redux/reducers/permissionSetCategorySlice";
import { showActionStatus } from "../../../redux/reducers/actionStatusSlice"; 
import Swal from 'sweetalert2';

const PermissionCategoryView = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [deleteActive, setDeleteActive] = useState();
    const permissionSetCategories = useSelector(selectAllPermissionSetCategories);
    const permissionSetCategoryStatus = useSelector(state => state.permissionSetCategories.status);

     useEffect(() => { 
       const currentPath = location.pathname;
       localStorage.setItem("previousPath", currentPath);
     }, [location.pathname]);

    useEffect(() => {
        if (permissionSetCategoryStatus === 'idle') {
            dispatch(fetchPermissionSetCategories());
        }
    }, [permissionSetCategoryStatus, dispatch]);

   
    const deleteCategory = (id) => {
        Swal.fire({
            title: 'Delete Permission Category',
            text: 'Are you sure you want to delete this category?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                onDeleteConfirm(id);
            }
        });
    }

    const onDeleteConfirm = async (id) => {
        dispatch(deletePermissionSetCategory({ id })).then((res) => {
            if (res?.type === 'permissionSetCategory/deletePermissionSetCategory/rejected') {
                Swal.fire({
                    icon: 'error',
                    title: 'Category Already Assigned',
                    text: 'The category has been assigned to Functional Permission Sets and End-User Roles',
                });
            } else {
                Swal.fire('Deleted', 'The category has been deleted.', 'success');
            }
            dispatch(fetchPermissionSetCategories());
        });
    }

    const columns = [
        { field: 'name', headerName: 'Name', flex: 2 },
        { field: 'description', headerName: 'Description', flex: 3 },
        {
            field: 'Actions', headerName: 'Actions', flex: 1, align: 'right', headerAlign: 'right', renderCell: (data) => {
                return <div>
                    <Stack direction='row' spacing={1}>
                        <Link className="custom-link" to={"/permission-set-categories/duplicate/" + data.id}>
                            <IconButton color='primary' size="medium" sx={{ borderRadius: 2, border: '1px solid' }} variant="outlined"><ContentCopy sx={{ fontSize: 20 }} /></IconButton>
                        </Link>
                        <Link className="custom-link" to={"/permission-set-categories/" + data.id}>
                            <IconButton color='warning' size="medium" sx={{ borderRadius: 2, border: '1px solid' }} variant="outlined"><ModeEdit sx={{ fontSize: 20 }} /></IconButton>
                        </Link>
                        <IconButton onClick={() => deleteCategory(data.id)} color='error' size="medium" sx={{ borderRadius: 2, border: '1px solid' }}><Delete sx={{ fontSize: 20 }} /></IconButton>
                    </Stack>
                </div>
            }
        }
    ];

    return (
        <Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "left" }}>
                <h2 className="page-title">Permission Set Categories</h2>
            </Grid>
            <DataTable columns={columns} data={permissionSetCategories} loading={permissionSetCategoryStatus} />
        </Grid>
    );
}

export default PermissionCategoryView;
