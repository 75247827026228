import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import { API_CONFIGURATIONS } from "../apiConfigurations";

let baseUrl = API_CONFIGURATIONS.NAVIGATOR_CONSOLIDATIONS;
const apiEndpoint = API_CONFIGURATIONS.GRANTED_NAVIGATOR_CONSOLIDATIONS;
const accessToken = localStorage.getItem('userToken');

let config = {
    mode: 'no-cors',
    headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        'xAccessToken': accessToken
    }
}

export const fetchNavigatorConsolidations = createAsyncThunk('navigatorConsolidation/fetchNavigatorConsolidation', async(data) => {
    //////debugger
    const response = await axios.post(apiEndpoint, data, config)
    return response.data;
})

export const initialState = {
    navigatorConsolidation: {},
    status: 'idle',
    error: null
}

const navigatorConsolidationSlice = createSlice({
    name: 'navigatorConsolidation',
    initialState,
    reducers: {

    },
    extraReducers(builder) {
        //////debugger
        builder
            .addCase(fetchNavigatorConsolidations.pending, (state, action) => {
                //////debugger
                state.status = 'loading'
            })
            .addCase(fetchNavigatorConsolidations.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.navigatorConsolidation = action.payload;
            })
            .addCase(fetchNavigatorConsolidations.rejected, (state, action) => {
                //////debugger
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const {} = navigatorConsolidationSlice.actions;
export default navigatorConsolidationSlice.reducer;

export const selectNavigatorConsolidations = (state) => state.navigatorConsolidation.navigatorConsolidation;