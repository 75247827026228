import { Search } from '@mui/icons-material';
import { Grid, TextField, InputAdornment, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Link } from "react-router-dom";

const getFilteredData = (data, filter) => {
    return (filter && filter.length > 0) ?
        data.filter(data => data.name && data.name.length > 0 && data.name.toLowerCase().includes(filter.toLowerCase())) : data;
}

const DataTable = ({ columns, data=[], loading }) => {
    const [filter, setFilter] = useState();

    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
    };
    const filteredData = getFilteredData(data, filter);
    console.log("FILTERING ", data, filter, filteredData)
    
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item lg={10} md={8} sm={12} xs={12}>
                    <TextField
                        size='small'
                        fullWidth
                        label='Search by Name'
                        onChange={(e) => setFilter(e.target.value)}
                        onInput={toInputUppercase}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search sx={{ color: '#000000' }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item lg={2} md={4} sm={12} xs={12} sx={{ textAlign: 'right' }}>
                    <Link className='custom-link' to={"/permission-set-categories/new"}>
                        <Button fullWidth disableElevation variant="contained" startIcon={<AddIcon />}>Add</Button>
                    </Link>
                </Grid>
            </Grid>

            <DataGrid
                columns={columns}
                rows={filteredData}
                sx={{ marginTop: 1 }}
                autoHeight
                checkboxSelection={false}
                pageSize={10}
                loading={loading==='loading'}
                getRowId={(row) => row.objkey}
                rowsPerPageOptions={[5]}
            />
        </div>
    )
}
export default DataTable;