import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import axios from 'axios';
import { API_CONFIGURATIONS } from "../apiConfigurations";

let baseUrl = API_CONFIGURATIONS.USER;
const apiEndpoint = baseUrl;
const accessToken = localStorage.getItem('userToken');

let config = {
    mode: 'no-cors',
    headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        'xAccessToken': accessToken
    }
}

export const initialState = {
    users: [],
    status: 'idle',
    error: null
}

export const fetchUsers = createAsyncThunk('users/fetchUsers', async() => {
    const response = await axios.get(apiEndpoint, config)
    return response.data;
})

const userSlice = createSlice({
    name: "users",
    initialState,
    reducers: {

    },
    extraReducers(builder) {
        builder
            .addCase(fetchUsers.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.status = 'succeeded'
                    // Add any fetched posts to the array
                state.users = state.users.concat(action.payload)
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export default userSlice.reducer;

export const selectAllUsers = state => state.users.users;