import { useSelector } from "react-redux";
import { NavLink, Outlet, useNavigate } from "react-router-dom";

const ProtectedRoute = () => {
  // const { userInfo, userToken } = useSelector((state) => state.authUser);
  const userToken = localStorage.getItem("userToken");

  const navigate = useNavigate();
  // console.log("PROTECTED ROUTE ", userInfo, userToken)
  if (!userToken) {
    navigate("/");
    window.location.reload();
    window.location.reload();
  } else {
  }
  return <Outlet />;
};
export default ProtectedRoute;
