import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import axios from 'axios';
import { API_CONFIGURATIONS } from "../apiConfigurations";

let baseUrl = API_CONFIGURATIONS.USER_GROUPS;
const apiEndpoint = baseUrl;
const accessToken = localStorage.getItem('userToken');

let config = {
    mode: 'no-cors',
    headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        'xAccessToken': accessToken
    }
}

export const initialState = {
    userGroups: [],
    status: 'idle',
    error: null
}

export const fetchUserGroups = createAsyncThunk('userGroups/fetchUserGroups', async() => {
    const response = await axios.get(apiEndpoint, config)
    return response.data;
})

const userGroupSlice = createSlice({
    name: "userGroup",
    initialState,
    reducers: {

    },
    extraReducers(builder) {
        builder
            .addCase(fetchUserGroups.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchUserGroups.fulfilled, (state, action) => {
                state.status = 'succeeded'
                    // Add any fetched posts to the array
                state.userGroups = state.userGroups.concat(action.payload)
            })
            .addCase(fetchUserGroups.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export default userGroupSlice.reducer;

export const selectAllUserGroups = state => state.userGroups.userGroups;