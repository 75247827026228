import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import axios from 'axios';
import swal from "sweetalert2";
import { API_CONFIGURATIONS } from "../apiConfigurations";

let baseUrl = API_CONFIGURATIONS.FUNCTIONAL_PERMISSION_SETS;
const apiEndpoint = baseUrl;
const endUserUrl = API_CONFIGURATIONS.ASSIGNED_FUNCTIONAL_PERMISSION_SETS;
const accessToken = localStorage.getItem("userToken");

let config = {
    mode: 'no-cors',
    headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        'xAccessToken': accessToken
    }
}

export const initialState = {
    functionalPermissionSets: [],
    status: 'idle',
    error: null
}

export const fetchFunctionalPermissionSets = createAsyncThunk('functionalPermissionSets/fetchFunctionalPermissionSets', async() => {
    const response = await axios.get(apiEndpoint, config)
    return response.data;
})

export const fetchAssignEndUserRoles = createAsyncThunk('functionalPermissionSets/fetchAssignEndUserRoles', async(record) => {

    const childUrl = endUserUrl + record;
    const response = await axios.get(childUrl, config);

    return response.data;
})

export const addNewFunctionalPermissionSet = createAsyncThunk('functionalPermissionSets/addNewFunctionalPermissionSet', async({ data, showStatus }) => {
    try {
        const response = await axios.post(apiEndpoint, data, config);
        if (response.status === 200) {
            showStatus({ open: true, severity: "success", message: "Functional Permission set added successfully" })
            return response.data;
        } else {
            showStatus({ open: true, severity: "error", message: "Functional Permission set adding failed" })
        }
    } catch (error) {
        swal.fire({
            title: 'This permission set already exists',
            text: "The permission set you're trying to create already exists in the system. Please choose a different name for your permission set.",
            icon: 'error',
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#d33',
        });

        throw error;
    }
});

export const updateFunctionalPermissionSet = createAsyncThunk('functionalPermissionSets/updateFunctionalPermissionSet', async({ data, showStatus }) => {
    const response = await axios.put(baseUrl, data, config);
    console.log("RESPONSE UPDATE", response)
    if (response.status === 200) {
        showStatus({ open: true, severity: "success", message: "Functional Permission set updated successfully" })
    } else {
        showStatus({ open: true, severity: "error", message: "Functional Permission set update failed" })
    }
    return response.data;
})

export const selectFunctionalPermissionSetByName = (state, permisisonName) => {
    const permission = state.functionalPermissionSets.functionalPermissionSets.find(permisison => permisison.name === permisisonName);

    return permission
}

export const deleteFunctionalPermissionSet = createAsyncThunk('functionalPermissionSets/deleteFunctionalPermissionSet', async({ id, showStatus }) => {
    const response = await axios.delete(`${baseUrl}/?role=${id}`, config);
    // if (response.status === 200) {
    //     showStatus({ open: true, severity: "success", message: "Permission set deleted successfully" })
    // } else {
    //     showStatus({ open: true, severity: "error", message: "Permission set delete failed" })
    // }
    return response.data;
})

const functionalPermissionSetSlice = createSlice({
    name: 'functionalPermissionSets',
    initialState,
    reducers: {
        addFunctionalPermissionSet: {
            reducer(state, action) {
                state.functionalPermissionSets.push(action.payload);
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchFunctionalPermissionSets.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchFunctionalPermissionSets.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.functionalPermissionSets = action.payload
            })
            .addCase(fetchFunctionalPermissionSets.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(fetchAssignEndUserRoles.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchAssignEndUserRoles.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.functionalPermissionSets = action.payload
            })
            .addCase(fetchAssignEndUserRoles.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(addNewFunctionalPermissionSet.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(addNewFunctionalPermissionSet.fulfilled, (state, action) => {
                state.status = 'succeeded'
                console.log("REDUX ", state, action)
                state.functionalPermissionSets = state.functionalPermissionSets
                    .concat(...action.payload)
                    .sort((a, b) => {
                        const nameA = a.name.toUpperCase();
                        const nameB = b.name.toUpperCase();
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0;
                    });
            })
            .addCase(addNewFunctionalPermissionSet.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(updateFunctionalPermissionSet.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(updateFunctionalPermissionSet.fulfilled, (state, action) => {
                state.status = 'succeeded'
                const permissionSets = [...current(state.functionalPermissionSets)]
                const index = permissionSets.findIndex(perm => perm.name === action.payload[0].name);
                permissionSets[index] = action.payload[0];
                state.functionalPermissionSets = permissionSets;
            })
            .addCase(updateFunctionalPermissionSet.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(deleteFunctionalPermissionSet.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(deleteFunctionalPermissionSet.fulfilled, (state, action) => {
                state.status = 'succeeded'
            })
            .addCase(deleteFunctionalPermissionSet.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
});

export default functionalPermissionSetSlice.reducer

export const selectAllFunctionalPermissionSets = state => state.functionalPermissionSets.functionalPermissionSets;

export const selectFunctionalPermissionSetById = (state, id) =>
    state.functionalPermissionSets.functionalPermissionSets.find(functionalPermissionSet => functionalPermissionSet.id === id);