import { Grid, Button, Fab, Stack } from "@mui/material";
import DataTable from "./DataTable";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  selectAllFunctionalPermissionSets,
  fetchFunctionalPermissionSets,
} from "../../../../redux/reducers/functionalPermissionSetSlice";

// import {
//   selectAllfFunctionalToEndUserMapping,
//   fetchfunctionalToEndUserMapping,
//   fetchChildLevelMapping,
// } from "../../../../redux/reducers/FunctionalToEndUserMappingSlice";

import React, { useEffect, useState } from "react";
import {
  selectAllFunctionalToEndUserMatrix,
  fetchfunctionalToEndUserMatrix,
  updateMappingMatrix,
  // filterMappings,
} from "../../../../redux/reducers/functionalToEndUserMatrixSlice";
import {
  fetchPermissionSetCategories,
  selectAllPermissionSetCategories,
} from "../../../../redux/reducers/permissionSetCategorySlice";
import { Save, Close } from "@mui/icons-material";
// import UpdateMappingPopup from "../../UpdateMappingPopup";
import UpdateConfirmationDialog from "../../UpdateConfirmationDialog";
import { Link, useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import AdvancedFilter from "./AdvancedFilter";

const FunctionalToEndUserMatrixView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { name, type } = useParams();

  const functionalPermissionSets = useSelector( selectAllFunctionalPermissionSets );
  const functionalToEndUserMatrix = useSelector( selectAllFunctionalToEndUserMatrix );
  const functionalPermissionSetStatus = useSelector( (state) => state.functionalPermissionSets.status);
  const functionalToEndUserMatrixStatus = useSelector( (state) => state.functionalToEndUserMatrix.status);
  const permissionSetCategories = useSelector(selectAllPermissionSetCategories);
  const permissionSetCategoryStatus = useSelector((state) => state.permissionSetCategories.status);
  // const [mappingUpdateId, setMappingUpdateId] = useState(null);
  const [updatedData, setUpdatedData] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [advancedFilter, setAdvancedFilter] = useState({});
  const [clearFilterRoute, setClearFilterRoute] = useState(false);

  useEffect(() => {
    const currentPath = location.pathname;
    localStorage.setItem("previousPath", currentPath);
  }, [location.pathname]);

  useEffect(() => {
    if (functionalPermissionSetStatus === "idle") {
      dispatch(fetchFunctionalPermissionSets());
    }
    if (functionalToEndUserMatrixStatus === "idle") {
      dispatch(fetchfunctionalToEndUserMatrix());
    }
    if (permissionSetCategoryStatus === "idle") {
      dispatch(fetchPermissionSetCategories());
    }
  }, [
    functionalPermissionSetStatus,
    functionalToEndUserMatrixStatus,
    permissionSetCategoryStatus,
    dispatch,
  ]);

   const onClickFilter = (filter) => { 
    if (name != undefined) {
       
      navigate(`/functional-permission-to-end-user`);
      if ((filter.filterTextPermissions == "") && (type == "functionalPermissionSets")) {
        filter.filterTextPermissions = name;
      }
      if (filter.filterTextRoles == "" && type == "endUserRoles") {
        filter.filterTextRoles = name;
      }
    }
    setAdvancedFilter(filter);
  };

  const onClickCell = (data, permission, type) => {
    let updatingIndex = null;
    let dataToUpdate = updatedData.find((mapping, index) => {
        updatingIndex = index;
        return mapping.endUserRole === data.endUserRole;
    });

    if (type === "add") {
        if (dataToUpdate) {
            const permissionIndex = dataToUpdate.functionalPermissionSetsAdd.indexOf(permission.name);
            const isInAddList = permissionIndex !== -1;
            const isInDelList = dataToUpdate.functionalPermissionSetsDel.includes(permission.name);

            if (isInDelList) {
                dataToUpdate.functionalPermissionSetsDel.splice(dataToUpdate.functionalPermissionSetsDel.indexOf(permission.name), 1);
            } else if (!isInAddList) {
                dataToUpdate.functionalPermissionSetsAdd.push(permission.name);
            } else {
                dataToUpdate.functionalPermissionSetsAdd.splice(permissionIndex, 1);
            }

            if (dataToUpdate.functionalPermissionSetsAdd.length === 0 && dataToUpdate.functionalPermissionSetsDel.length === 0) {
                const updatedState = [...updatedData];
                updatedState.splice(updatingIndex, 1);
                setUpdatedData(updatedState);
            } else {
                const updatedState = [...updatedData];
                updatedState[updatingIndex] = dataToUpdate;
                setUpdatedData(updatedState);
            }
        } else {
            setUpdatedData([
                {
                    endUserRole: data.endUserRole,
                    functionalPermissionSetsAdd: [permission.name],
                    functionalPermissionSetsDel: [],
                },
                ...updatedData,
            ]);
        }
    } else {
        if (dataToUpdate) {
            const permissionIndex = dataToUpdate.functionalPermissionSetsDel.indexOf(permission.name);
            const isInAddList = dataToUpdate.functionalPermissionSetsAdd.includes(permission.name);
            const isInDelList = permissionIndex !== -1;

            if (isInAddList) {
                dataToUpdate.functionalPermissionSetsAdd.splice(dataToUpdate.functionalPermissionSetsAdd.indexOf(permission.name), 1);
            } else if (!isInDelList) {
                dataToUpdate.functionalPermissionSetsDel.push(permission.name);
            } else {
                dataToUpdate.functionalPermissionSetsDel.splice(permissionIndex, 1);
            }

            if (dataToUpdate.functionalPermissionSetsAdd.length === 0 && dataToUpdate.functionalPermissionSetsDel.length === 0) {
                const updatedState = [...updatedData];
                updatedState.splice(updatingIndex, 1);
                setUpdatedData(updatedState);
            } else {
                const updatedState = [...updatedData];
                updatedState[updatingIndex] = dataToUpdate;
                setUpdatedData(updatedState);
            }
        } else {
            setUpdatedData([
                {
                    endUserRole: data.endUserRole,
                    functionalPermissionSetsDel: [permission.name],
                    functionalPermissionSetsAdd: [],
                },
                ...updatedData,
            ]);
        }
    }

    dispatch(updateMappingMatrix({ data, permission, type }));
  };

  // const onCloseMappingUpdate = () => {
  //   setMappingUpdateId(null);
  // };

   const onClose = () => {
    setUpdatedData([]);
    setOpenConfirmation(false);
  };

  const onCancel = () => {
    //dispatch(fetchfunctionalToEndUserMatrix());
    window.location.reload();
    setUpdatedData([]);
    setOpenConfirmation(false);
  };

  const clearFilter = () => {
    setAdvancedFilter({});
    if (name != undefined) {
      navigate(`/functional-permission-to-end-user`);
    }
  };



  const handleSave = () => {
    // setOpenConfirmation(false);
    setUpdatedData([]);
  };

  const handleCancel = () => {
    setUpdatedData([]);
    setOpenConfirmation(false);
  };

  const onDiscardChanges = () => {
    window.location.reload();
  };

  const checkDataValidity = (data) => {
  for (const item of data) {
    if (item.functionalPermissionSetsAdd.length !== 0 || item.functionalPermissionSetsDel.length !== 0) {
      return true; // If any object has non-empty arrays, return true
    }
  }
  return false; // If all objects have empty arrays, return false
};


  const dataLoading =
    functionalPermissionSetStatus === "loading" ||
    functionalToEndUserMatrixStatus === "loading";

  return (
    <Grid container spacing={2}>
      <UpdateConfirmationDialog
        openConfirmation={openConfirmation}
        type={"save"}
        updatedData={updatedData}
        handleSave={handleSave}
        setOpenConfirmation={setOpenConfirmation}
        handleCancel={handleCancel}
        handleClose={onClose}
        handleCancelCell={onCancel}
      />
      {/* <UpdateMappingPopup
        mappingId={mappingUpdateId}
        handleClose={handleCancel}
        setOpenConfirmation={setOpenConfirmation}
      /> */}

      <Grid item lg={6} md={6} sm={6} xs={6} sx={{ textAlign: "left" }}>
        <h2 className="page-title">
          Functional Permission Sets - End-User Roles Matrix
        </h2>
      </Grid>
      <Grid item lg={3} md={3} sm={3} xs={3} sx={{ textAlign: "left" }}>
        <Link className="custom-link" to={"/functional-permission-sets/new"}>
          <Button
            fullWidth
            disableElevation
            variant="contained"
            startIcon={<AddIcon />}
          >
            Functional Permission Set
          </Button>
        </Link>
      </Grid>
      <Grid item lg={3} md={3} sm={3} xs={3} sx={{ textAlign: "left" }}>
        <Link className="custom-link" to={"/end-user-roles/new"}>
          <Button
            fullWidth
            disableElevation
            variant="contained"
            startIcon={<AddIcon />}
          >
            End-User Role
          </Button>
        </Link>
      </Grid>
      <Grid item lg={12}>
        <AdvancedFilter
          permissionSetCategories={permissionSetCategories}
          onFilter={onClickFilter}
          onClearFilter={clearFilter}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginBottom: 50 }}>
        <Grid container columns={36}>
          <Grid item lg={36} className="pt-text-x-axis">
            End-User Roles
          </Grid>
          <Grid item lg={1} className="pt-text-y-axis">
            Functional Permission Sets
          </Grid>
          <Grid item lg={35}>
            <DataTable
              data={functionalToEndUserMatrix}
              functionalPermissionSets={functionalPermissionSets}
              filters={advancedFilter}
              loading={dataLoading}
              onClickCell={onClickCell}
              clearRoute={setClearFilterRoute}
            />
          </Grid>
        </Grid>
      </Grid>
      {updatedData && updatedData.length > 0  && checkDataValidity(updatedData) && (
        <Stack
          direction="row"
          spacing={2}
          style={{ position: "fixed", right: 20, bottom: 20, zIndex: 1000 }}
        >
          <Fab variant="extended" onClick={() => onCancel()}>
            <Close sx={{ mr: 1 }} />
            Cancel
          </Fab>
          <Fab
            variant="extended"
            color="primary"
            onClick={() => setOpenConfirmation(true)}
          >
            <Save sx={{ mr: 1 }} />
            Save
          </Fab>
        </Stack>
      )}
    </Grid>
  );
};

export default FunctionalToEndUserMatrixView;
