import {
  Alert,
  Autocomplete,
  Button,
  Grid,
  LinearProgress,
  Snackbar,
  Stack,
  TextField,
  FormHelperText,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  selectAllPermissionSetCategories,
  fetchPermissionSetCategories,
} from "../../../redux/reducers/permissionSetCategorySlice";
import {
  addNewFunctionalPermissionSet,
  fetchFunctionalPermissionSets,
  selectFunctionalPermissionSetByName,
  updateFunctionalPermissionSet,
} from "../../../redux/reducers/functionalPermissionSetSlice";

const PermissionUpdate = () => {
  const { permissionName, action } = useParams();
  const [loading, setLoading] = useState(permissionName !== "new");
  const [permissionData, setPermissionData] = useState({});
  const [textLegnth, setTextLegnth] = useState(false);
  const [hasSpace, setHasSpace] = useState(false);
  const [snakebarOps, setSnakebarOps] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permissionSetCategories = useSelector(selectAllPermissionSetCategories);
  const permissionSetCategoryStatus = useSelector(
    (state) => state.permissionSetCategories.status
  );
  const duplicatePermission = useSelector((state) =>
    selectFunctionalPermissionSetByName(state, permissionName)
  );
  const functionalPermissionSetStatus = useSelector(
    (state) => state.functionalPermissionSets.status
  );

  useEffect(() => {
    if (permissionSetCategoryStatus === "idle") {
      dispatch(fetchPermissionSetCategories());
    }
    if (functionalPermissionSetStatus === "idle") {
      dispatch(fetchFunctionalPermissionSets());
    }
    if (action === "duplicate" && duplicatePermission) {
      const data = {
        ...duplicatePermission,
        name: duplicatePermission.name + "_COPY",
      };
      setPermissionData(data);
    } else if (permissionName && permissionName !== "new") {
      setPermissionData(duplicatePermission);
    }
  }, [
    permissionName,
    permissionSetCategories,
    functionalPermissionSetStatus,
    dispatch,
    permissionSetCategoryStatus,
    action,
    duplicatePermission,
  ]);

  const handleChange = (field, value) => {
    const updatedValue = {};
    updatedValue[field] = value;

    switch (field) {
      case "name":
        setPermissionData({
          ...permissionData,
          ...updatedValue,
        });
        setHasSpace(value.includes(" "));
        return;
      case "description":
        setPermissionData({
          ...permissionData,
          ...updatedValue,
        });
        return;
      case "category":
        setPermissionData({
          ...permissionData,
          ...updatedValue,
        });
        return;
      default:
        console.log("No case defined");
        return;
    }
  };

  const toInputUppercase = (e) => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  const onSubmit = () => {
    return permissionName === "new"
      ? createPermissionSet()
      : action === "duplicate"
      ? createPermissionSet()
      : updatePermissionSet();
  };

  const createPermissionSet = async () => {
    const newPermission = [
      {
        name: permissionData.name,
        description: permissionData.description,
        category: permissionData.category,
      },
    ];

    dispatch(
      addNewFunctionalPermissionSet({
        data: newPermission,
        showStatus: setSnakebarOps,
      })
    ).then((response) => {
      if (
        response.type ===
        "functionalPermissionSets/addNewFunctionalPermissionSet/fulfilled"
      ) {
        navigate(-1);
      }
    });
  };

  const updatePermissionSet = async () => {
    const updatedPermission = [
      {
        name: permissionData.name,
        description: permissionData.description,
        category: permissionData.category,
      },
    ];

    dispatch(
      updateFunctionalPermissionSet({
        data: updatedPermission,
        showStatus: setSnakebarOps,
      })
    ).then((response) => {
      if (
        response.type ===
        "functionalPermissionSets/updateFunctionalPermissionSet/fulfilled"
      ) {
        navigate(-1);
      }
    });
  };

  return (
    <div>
      <Snackbar
        open={snakebarOps.open}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        severity={snakebarOps.severity}
        autoHideDuration={2000}
        onClose={() => setSnakebarOps({ open: false })}
      >
        <Alert
          onClose={() => setSnakebarOps({ open: false })}
          severity={snakebarOps.severity}
          sx={{ width: "100%" }}
        >
          {snakebarOps.message}
        </Alert>
      </Snackbar>
      {/* {functionalPermissionSetStatus === 'idle' || permissionSetCategoryStatus === 'idle' ? <LinearProgress sx={{ m: 50 }} color="inherit" /> : */}
      {functionalPermissionSetStatus === "loading" ? (
        <LinearProgress sx={{ m: 50 }} color="inherit" />
      ) : (
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "left" }}>
            <h2 className="page-title">
              {permissionName === "new"
                ? "Create New"
                : action === "duplicate"
                ? "Duplicate"
                : "Edit"}{" "}
              Permission Set
            </h2>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12} gutter={4}>
            <Stack spacing={2}>
              <TextField
                value={permissionData.name || ""}
                fullWidth
                size="small"
                label="Name"
                disabled={permissionName !== "new" && action !== "duplicate"}
                name="Name"
                onChange={(e) => {
                  handleChange("name", e.target.value);
                  setTextLegnth(e.target.value.length > 30 ? true : false);
                }}
                onInput={toInputUppercase}
                inputProps={{ maxLength: 30 }}
                error={permissionData.name && (permissionData.name.length >= 30 || hasSpace)}
                helperText={
                  permissionData.name && permissionData.name.length === 30
                    ? "Reached maximum length (30 characters)"
                    : hasSpace
                    ? "Spaces are not allowed. Please use special characters (!@#$%&_) instead."
                    : ""
                }
              />
              <TextField
                value={permissionData.description}
                fullWidth
                size="small"
                label="Description"
                name="Description"
                rows={4}
                //placeholder="Permission set Description"
                onChange={(e) => {
                  handleChange("description", e.target.value);
                  setTextLegnth(e.target.value.length > 200 ? true : false);
                }}
                inputProps={{ maxLength: 200 }}
                error={permissionData.description && (permissionData.description.length >= 200)}
                helperText={
                  permissionData.description && permissionData.description.length === 200
                  ? "Reached maximum length (200 characters)"
                  : ""
                } 
              />
              {(permissionData.category !== undefined ||
                permissionName === "new") && (
                <Autocomplete
                  value={permissionData?.category}
                  id="category"
                  size="small"
                  //disableClearable
                  options={permissionSetCategories.map(
                    (category) => category.name
                  )}
                  onChange={(event, value) => handleChange("category", value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Category"
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              )}
              <Stack direction="row" spacing={2}>
                <Button
                  variant="outlined"
                  color="warning"
                  onClick={() => navigate(-1)}
                  sx={{ flex: 1 }}
                  disableElevation
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{ flex: 1 }}
                  disableElevation
                  onClick={() => onSubmit()}
                  disabled={textLegnth || hasSpace}
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default PermissionUpdate;
