import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import axios from 'axios';
import swal from "sweetalert2";
import { API_CONFIGURATIONS } from "../apiConfigurations";

let baseUrl = API_CONFIGURATIONS.END_USER_ROLES;
const apiEndpoint = baseUrl;
const endUserUrl = API_CONFIGURATIONS.ASSIGNED_END_USER_ROLES;
const accessToken = localStorage.getItem('userToken');

let config = {
    mode: 'no-cors',
    headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        'xAccessToken': accessToken
    }
}

export const initialState = {
    endUserRoles: [],
    status: 'idle',
    error: null
}

export const fetchEndUserRoles = createAsyncThunk('endUserRoles/fetchEndUserRoles', async() => {
    const response = await axios.get(apiEndpoint, config)
    return response.data;
})

export const fetchAssignedUsers = createAsyncThunk('functionalPermissionSets/fetchAssignedUsers', async(record) => {
    const childUrl = endUserUrl + record;
    const response = await axios.get(childUrl, config);
    return response.data;
})

export const addNewEndUserRole = createAsyncThunk("endUserRoles/addEndUserRole", async({ data, showStatus }) => {
    try {
        const response = await axios.post(apiEndpoint, data, config);
        if (response.status === 200) {
            showStatus({ open: true, severity: "success", message: "End user role added successfully" })
            return response.data;
        } else {
            showStatus({ open: true, severity: "error", message: "End user role adding failed" })
        }
    } catch (error) {
        swal.fire({
            title: 'This end user role already exists',
            text: "The end user role you're trying to create already exists in the system. Please choose a different name for your end user role.",
            icon: 'error',
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#d33',
        })

        throw error;
    }
});

export const selectEndUserRoleByName = (state, roleName) => {
    const role = state.endUserRoles.endUserRoles.find(role => role.name === roleName);

    console.log("STATE ", role)
    return role
}

export const updateEndUserRole = createAsyncThunk('endUserRoles/updateEndUserRole', async({ data, showStatus }) => {
    const response = await axios.put(baseUrl, data, config);
    if (response.status === 200) {
        showStatus({ open: true, severity: "success", message: "Permission set category updated successfully" })
    } else {
        showStatus({ open: true, severity: "error", message: "Permission set category update failed" })
    }
    return response.data;
})

export const deleteEndUserRole = createAsyncThunk('endUserRoles/deleteEndUserRole', async({ id, showStatus }) => {
    const response = await axios.delete(`${baseUrl}/?role=${id}`, config);
    // if (response.status === 200) {
    //     showStatus({ open: true, severity: "success", message: "End-User role deleted successfully" })
    // } else {
    //     showStatus({ open: true, severity: "error", message: "End-User ROle delete failed" })
    // }
    return response.data;
})

const endUserRoleSlice = createSlice({
    name: 'endUserRoles',
    initialState,
    reducers: {

    },
    extraReducers(builder) {
        builder
            .addCase(fetchEndUserRoles.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchEndUserRoles.fulfilled, (state, action) => {
                state.status = 'succeeded'
                    // Add any fetched posts to the array
                state.endUserRoles = action.payload
            })
            .addCase(fetchEndUserRoles.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(fetchAssignedUsers.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchAssignedUsers.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.endUserRoles = action.payload
            })
            .addCase(fetchAssignedUsers.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(addNewEndUserRole.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(addNewEndUserRole.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.endUserRoles = state.endUserRoles
                    .concat(action.payload)
                    .sort((a, b) => {
                        const nameA = a.name.toUpperCase();
                        const nameB = b.name.toUpperCase();
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0;
                    });
            })
            .addCase(addNewEndUserRole.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(updateEndUserRole.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(updateEndUserRole.fulfilled, (state, action) => {
                state.status = 'succeeded'
                const endUserRoles = [...current(state.endUserRoles)]
                const index = endUserRoles.findIndex(permisison => permisison.name === action.payload[0].name);
                endUserRoles[index] = action.payload[0];
                state.endUserRoles = endUserRoles;
            })
            .addCase(updateEndUserRole.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(deleteEndUserRole.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(deleteEndUserRole.fulfilled, (state, action) => {
                state.status = 'succeeded'
            })
            .addCase(deleteEndUserRole.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
});


export default endUserRoleSlice.reducer;

export const selectAllEndUserRoles = state => state.endUserRoles.endUserRoles;

export const selectEndUserRoleById = (state, id) =>
    state.endUserRoles.endUserRoles.find(endUserRole => endUserRole.id === id);