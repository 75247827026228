import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";
import { API_CONFIGURATIONS } from "../apiConfigurations";

let baseUrl = API_CONFIGURATIONS.END_USER_TO_FUNCTIONAL_PERMISSION_SETS;
const apiEndpoint = baseUrl;
const accessToken = localStorage.getItem("userToken");

let config = {
  mode: "no-cors",
  headers: {
    "Access-Control-Allow-Origin": "http://localhost:3000",
    xAccessToken: accessToken,
  },
};

export const initialState = {
  functionalToEndUserMatrix: [],
  status: "idle",
  error: null,
};

export const fetchfunctionalToEndUserMatrix = createAsyncThunk(
  "functionalToEndUserMatrix/fetchFunctionalToEndUserMatrix",
  async () => {
    const response = await axios.get(apiEndpoint, config);
    return response.data;
  }
);

export const addNewMapping = createAsyncThunk(
  "functionalToEndUserMatrix/addNewMapping",
  async ({ data, closePopup, showStatus }) => {
    const response = await axios.post(baseUrl, data, config);
    if (response.status === 200) {
      showStatus({
        open: true,
        severity: "success",
        message: "Mapping added successfully",
      });
      setTimeout(() => {
        closePopup();
      }, 2000);
    } else {
      showStatus({
        open: true,
        severity: "error",
        message: "Mapping adding failed",
      });
    }
    return response.data;
  }
);

export const updateMappings = createAsyncThunk(
  "functionalToEndUserMatrix/updateMappings",
  async ({ data, closePopup }) => {
    const response = await axios.post(baseUrl, data, config);
    if (response.status === 200) {
      // showStatus({ open: true, severity: "success", message: "Mapping added successfully" })
      setTimeout(() => {
        // closePopup();
      }, 2000);
    } else {
      // showStatus({ open: true, severity: "error", message: "Mapping adding failed" })
      setTimeout(() => {
        // closePopup();
      }, 2000);
    }
    return response.data;
  }
);

const fetchfunctionalToEndUserMatrixSlice = createSlice({
  name: "functionalToEndUserMatrix",
  initialState,
  reducers: {
    addfunctionalToEndUserMatrix: {
      reducer(state, action) {
        state.functionalToEndUserMatrix.push(action.payload);
      },
    },
    updateMappingMatrix: {
      reducer(state, action) {
        const { data, permission, type } = action.payload;
        console.log("Data ", action.payload);
        const functionalToEndUserMatrix = [
          ...current(state.functionalToEndUserMatrix),
        ];
        const matrixIndex = functionalToEndUserMatrix.findIndex(
          (matrix) => matrix.endUserRole === data.endUserRole
        );
        const updatedMapping = { ...functionalToEndUserMatrix[matrixIndex] };
        let arrayUpdate = [...updatedMapping.functionalPermissionSets];

        if (type === "add") {
          arrayUpdate.push(permission);
        } else {
          arrayUpdate.splice(
            arrayUpdate.findIndex((elem) => elem.name === permission.name),
            1
          );
        }

        updatedMapping.functionalPermissionSets = arrayUpdate;
        functionalToEndUserMatrix[matrixIndex] = updatedMapping;

        return {
          ...state,
          functionalToEndUserMatrix,
        };
      },
    },
    filterMappings: {
      reducer(state, action) {
        const { filter } = action.payload;
        console.log(
          "State ",
          ...current(state.functionalToEndUserMatrix),
          filter
        );
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchfunctionalToEndUserMatrix.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchfunctionalToEndUserMatrix.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched posts to the array
        state.functionalToEndUserMatrix =
          state.functionalToEndUserMatrix.concat(action.payload);
      })
      .addCase(fetchfunctionalToEndUserMatrix.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewMapping.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addNewMapping.fulfilled, (state, action) => {
        state.status = "succeeded";
        const functionalToEndUserMatrix = [...state.functionalToEndUserMatrix];
        const mappingIndex = functionalToEndUserMatrix.find(
          (mapping) => mapping.endUserRole === action.payload.endUserRole
        );
        functionalToEndUserMatrix[mappingIndex] = action.payload;

        return { ...state, functionalToEndUserMatrix };
      })
      .addCase(addNewMapping.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateMappings.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateMappings.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateMappings.fulfilled, (state, action) => {
        state.status = "succeeded";
        const functionalToEndUserMatrix = [...state.functionalToEndUserMatrix];
        action.payload.forEach((updatedObject) => {
          const mappingIndex = functionalToEndUserMatrix.find(
            (mapping) => mapping.endUserRole === updatedObject.endUserRole
          );
          functionalToEndUserMatrix[mappingIndex] = updatedObject;
        });
      });
  },
});

export const {
  addfunctionalToEndUserMatrix,
  updateMappingMatrix,
  filterMappings,
} = fetchfunctionalToEndUserMatrixSlice.actions;

export default fetchfunctionalToEndUserMatrixSlice.reducer;

export const selectAllFunctionalToEndUserMatrix = (state) =>
  state.functionalToEndUserMatrix.functionalToEndUserMatrix;
export const getMappingByRole = (state, role) =>
  state.functionalToEndUserMatrix.functionalToEndUserMatrix.find(
    (mapping) => mapping.endUserRole === role
  );
