import {
  Grid,
  Button,
  Autocomplete,
  InputAdornment,
  TextField,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormControlLabel,
  Switch,
  Chip,
} from "@mui/material";
import { Search, FilterAlt, Close as CloseIcon } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const searchOptions = [
  { value: "functionalPermissionSets", label: "Functional Permission Sets" },
  { value: "endUserRoles", label: "End-User Roles" },
];

const AdvancedFilter = ({
  onFilter,
  permissionSetCategories = [],
  onClearFilter,
  advancedFilter,
}) => {
  const { type, name } = useParams();
  const [filter, setFilter] = useState({
    filterTextPermissions: "",
    filterTextRoles: "",
    activePermissionMappings: false,
    activeRolesMappings: false,
    functionalCat: [],
    endUserCat: [],
    resetRouteFlag: false,
  });

  // useEffect(() => {
  //     if (type && type.length > 0 && name && name.length > 0) {
  //         const filterType = searchOptions.find(option => option.value === type);
  //         const newFilter = {
  //             ...filter,
  //             filterType: filterType.label,
  //             filterText: name,
  //         }
  //         setFilter(newFilter);
  //         onFilter(newFilter);
  //     }
  // }, [advancedFilter])
  // console.log("Filter ", type, name)

  const toInputUppercase = (e) => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  const clear = () => {
    setFilter({
      filterTextPermissions: "",
      filterTextRoles: "",
      activePermissionMappings: false,
      activeRolesMappings: false,
      functionalCat: [],
      endUserCat: [],
      resetRouteFlag: false,
    });
    onClearFilter();
  };

  const onFilterUpdate = (type, value) => {
    switch (type) {
      // case 'filterType': {
      //     setFilter({ ...filter, filterType: value });
      //     return;
      // }
      case "filterTextPermissions": {
        setFilter({ ...filter, filterTextPermissions: value });
        return;
      }
      case "filterTextRoles": {
        setFilter({ ...filter, filterTextRoles: value });
        return;
      }
      case "activePermissionMappings": {
        setFilter({ ...filter, activePermissionMappings: value });
        return;
      }
      case "activeRolesMappings": {
        setFilter({ ...filter, activeRolesMappings: value });
        return;
      }
      case "functionalCat": {
        setFilter({ ...filter, functionalCat: value });
        return;
      }
      case "endUserCat": {
        setFilter({ ...filter, endUserCat: value });
        return;
      }
      default: {
        console.log("No type defined");
      }
    }
  };

  const handleDeleteFunctionalCat = (category) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      functionalCat: prevFilter.functionalCat.filter(cat => cat !== category),
    }));
  };

  const handleDeleteEndUserCat = (category) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      endUserCat: prevFilter.endUserCat.filter(cat => cat !== category),
    }));
  };

  return (
    <Grid container spacing={2}>
      <Grid item lg={4}>
        <TextField
          size="small"
          fullWidth
          label={"Search by Functional Permission Sets"}
          value={filter?.filterTextPermissions}
          onChange={(e) =>
            onFilterUpdate("filterTextPermissions", e.target.value)
          }
          onInput={toInputUppercase}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Search sx={{ color: "#000000" }} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      
      {/* <Grid item lg={3.5}>
        <FormControl fullWidth size="small">
          <InputLabel id="func-cat-label">
            Functional Permission Set Category
          </InputLabel>
          <Select
            //multiple
            fullWidth
            label="Functional Category"
            labelId="func-cat-label"
            input={<OutlinedInput label="Functional Permission Set Category" />}
            value={filter.functionalCat}
            style={{ textAlign: "left" }}
            onChange={(e) => onFilterUpdate("functionalCat", e.target.value)}
            //disabled={filter.filterType === searchOptions[1]?.label}
          >
            {permissionSetCategories &&
              permissionSetCategories.map((category) => (
                <MenuItem key={category.name} value={category.name}>
                  {category.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid> */}

      <Grid item lg={3.5}>
        <FormControl fullWidth size="small">
          <Autocomplete
          size='small'
            options={permissionSetCategories.map((category) => category.name)}
            value={filter.functionalCat}
            onChange={(event, newValue) => {
              onFilterUpdate('functionalCat', newValue);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option}
                  label={option}
                  {...getTagProps({ index })}
                  deleteIcon={<CloseIcon onMouseDown={(event) => event.stopPropagation()} />}
                  onDelete={() => handleDeleteFunctionalCat(option)}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Functional Permission Set Category"
              />
            )}
          />
        </FormControl>
      </Grid>

      <Grid item lg={4.5} style={{ textAlign: "left" }}>
        <FormControlLabel
          onChange={(e) =>
            onFilterUpdate("activePermissionMappings", e.target.checked)
          }
          size="small"
          control={<Switch checked={filter.activePermissionMappings} />}
          label="Active mappings for Functional Permission Sets"
        />
      </Grid>
      <Grid item lg={4}>
        <TextField
          size="small"
          fullWidth
          label={"Search by End-User Roles"}
          value={filter?.filterTextRoles}
          onChange={(e) => onFilterUpdate("filterTextRoles", e.target.value)}
          onInput={toInputUppercase}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Search sx={{ color: "#000000" }} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      {/* <Grid item lg={3.5}>
        <FormControl fullWidth size="small">
          <InputLabel id="func-cat-label">End-User Role Category</InputLabel>
          <Select
            //multiple
            fullWidth
            label="End-User Category"
            labelId="func-cat-label"
            input={<OutlinedInput label="End-User Category" />}
            value={filter.endUserCat}
            style={{ textAlign: "left" }}
            onChange={(e) => onFilterUpdate("endUserCat", e.target.value)}
            //disabled={filter.filterType === searchOptions[0]?.label}
          >
            {permissionSetCategories &&
              permissionSetCategories.map((category) => (
                <MenuItem key={category.name} value={category.name}>
                  {category.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid> */}

      <Grid item lg={3.5}>
        <FormControl fullWidth size="small">
          <Autocomplete
          size='small'
            options={permissionSetCategories.map((category) => category.name)}
            value={filter.endUserCat}
            onChange={(event, newValue) => {
              onFilterUpdate('endUserCat', newValue);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option}
                  label={option}
                  {...getTagProps({ index })}
                  deleteIcon={<CloseIcon onMouseDown={(event) => event.stopPropagation()} />}
                  onDelete={() => handleDeleteEndUserCat(option)}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="End-User Role Category"
              />
            )}
          />
        </FormControl>
      </Grid>

      <Grid item lg={4.5} style={{ textAlign: "left" }}>
        <FormControlLabel
          onChange={(e) =>
            onFilterUpdate("activeRolesMappings", e.target.checked)
          }
          size="small"
          control={<Switch checked={filter.activeRolesMappings} />}
          label="Active mappings for End-User Roles"
        />
      </Grid>
      <Grid item lg={8}>
        {" "}
      </Grid>
      <Grid item lg={2}>
        <Button
          onClick={() => clear()}
          fullWidth
          disableElevation
          variant="outlined"
          startIcon={<FilterAlt />}
        >
          Clear Filter
        </Button>
      </Grid>
      <Grid item lg={2}>
        <Button
          onClick={() => onFilter(filter)}
          fullWidth
          disableElevation
          variant="contained"
          startIcon={<FilterAlt />}
        >
          Apply Filter
        </Button>
      </Grid>
    </Grid>
  );
};

export default AdvancedFilter;
