import { Routes, Route, Navigate } from "react-router-dom";
import NoPage from "./pages/NoPage";
import PermissionUpdate from "./pages/FunctionalPermissionSets/Update";
import PermissionsView from "./pages/FunctionalPermissionSets/View";
import EndUserUpdate from "./pages/EndUserRoles/Update";
import EndUserView from "./pages/EndUserRoles/View";
import FunctionalToEndUserListView from "./pages/FunctionalToEndUser/List/View";
import PermissionSetCategoryView from "./pages/PermissionSetCategory/View";
import PermissionSetCategoryUpdate from "./pages/PermissionSetCategory/Update";
import FunctionalToEndUserMatrixView from "./pages/FunctionalToEndUser/Matrix/View";
import UserToEndUserRoles from "./pages/UserToEndUserRoles";
import NavigatorToFunctionalPermissionSets from "./pages/NavigatorFunctionalPermissionSets/View";
import NavigatorToFunctionalPermissionSetsMulti from "./pages/NavigatorFunctionalPermissionSetsMulti/View";
import SignIn from "./pages/SignIn";
import ReportsDashbord from "./pages/Reports";
import UserListView from "./pages/Reports/Users/UserListView";
import PermissionListView from "./pages/Reports/Permissions/PermissionListView/nfps-index";
import EndUserRoleBreakDownListView from "./pages/Reports/Permissions/PermissionListView/eurb-index";
import ProtectedRoute from "./ProtectedRoute";
import Templates from "./pages/Templates";

export const renderRoutes = (routes = []) => {
  return (
    <Routes>
      <Route path={"/"} element={<Navigate to={"/sign-in"} />} />
      <Route path={"/sign-in"} element={<SignIn />} />
      <Route element={<ProtectedRoute />}>
        <Route
          path={"/functional-permission-sets"}
          element={<PermissionsView />}
        />
        <Route
          path={"/functional-permission-sets/:permissionName"}
          element={<PermissionUpdate />}
        />
        <Route
          path={"/functional-permission-sets/:action/:permissionName"}
          element={<PermissionUpdate />}
        />
        <Route path={"/end-user-roles"} element={<EndUserView />} />
        <Route
          path={"/end-user-roles/:permissionName"}
          element={<EndUserUpdate />}
        />
        <Route
          path={"/end-user-roles/:action/:permissionName"}
          element={<EndUserUpdate />}
        />
        {/* <Route path={'/functional-permission-to-end-user'} element={<FunctionalToEndUserListView />} /> */}
        <Route
          path={"/functional-permission-to-end-user"}
          element={<FunctionalToEndUserMatrixView />}
        />
        <Route
          path={"/functional-permission-to-end-user/:type/:name"}
          element={<FunctionalToEndUserMatrixView />}
        />
        <Route path={"/404"} element={<NoPage />} />
        <Route path={"*"} element={<NoPage />} />
        <Route
          path={"/permission-set-categories"}
          element={<PermissionSetCategoryView />}
        />
        <Route
          path={"/permission-set-categories/:categoryId"}
          element={<PermissionSetCategoryUpdate />}
        />
        <Route
          path={"/permission-set-categories/:action/:categoryId"}
          element={<PermissionSetCategoryUpdate />}
        />
        <Route
          path={"/users-to-end-user-roles"}
          element={<UserToEndUserRoles />}
        />
        <Route
          path={"/users-to-end-user-roles/:type/:name"}
          element={<UserToEndUserRoles />}
        />

        {/* <Route path={"/navigator-to-functional-permission-sets"} element={<NavigatorToFunctionalPermissionSets />} /> */}
        <Route
          path={"/navigator-to-functional-permission-sets"}
          element={<NavigatorToFunctionalPermissionSetsMulti />}
        />
        <Route
          path={"/navigator-to-functional-permission-sets/:type/:name"}
          element={<NavigatorToFunctionalPermissionSetsMulti />}
        />
        <Route path={"/reports"} element={<ReportsDashbord />} />
        <Route path={"/reports/users"} element={<UserListView />} />
        <Route path={"/reports/permissions/nfps"} element={<PermissionListView />} />
        <Route path={"/reports/permissions/eurb"} element={<EndUserRoleBreakDownListView />} />
        <Route path={"/predefined-templates"} element={<Templates />} />
      </Route>
    </Routes>
  );
};

const routes = [
  {
    id: "nvitm009",
    title: "Permission Set Categories",
    target: "permission-set-categories",
    disabled: false,
  },
  {
    id: "nvitm002",
    title: "Functional Permission Sets",
    target: "functional-permission-sets",
  },
  {
    id: "nvitm006",
    title: "Navigator to Functional Permission Sets",
    target: "navigator-to-functional-permission-sets",
  },
  {
    id: "nvitm001",
    title: "End-User Roles",
    target: "end-user-roles",
  },
  // {
  //     "id": "nvitm003",
  //     "title": "Functional Permission Sets End-User Roles",
  //     "target": "functional-permission-to-end-user",
  // },
  {
    id: "nvitm003",
    title: "Functional Permission Sets End-User Roles",
    target: "functional-permission-to-end-user",
  },
  {
    id: "nvitm004",
    title: "Users to End-User Roles",
    target: "users-to-end-user-roles",
  },
  {
    id: "nvitm008",
    title: "Reporting",
    target: "reports",
  },
  {
    id: "nvitm0010",
    title: "Predefined Templates",
    target: "predefined-templates",
  },
];

export default routes;
