import { Card, CardContent, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material"
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import ArticleIcon from '@mui/icons-material/Article';
import { Link } from "react-router-dom";

const ReportTypeCard = ({ reportType }) => {
    const color = reportType.color ? reportType.color : '#252f3e';
    console.log(reportType.reports)
    const renderReports = () => {
        const reportList = [];
        reportType && reportType.reports && reportType.reports.map(report => {
            reportList.push(
                <Link to={ report.target }>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon sx={{ minWidth: 36 }}>
                                <ArticleIcon sx={{ color: "#ffffff" }} />
                            </ListItemIcon>
                            <ListItemText primary={report.title} sx={{ color: "#ffffff" }} />
                        </ListItemButton>
                    </ListItem>
                </Link>
            )
        })
        return reportList;
    }
    return (
        <Grid item lg={6} md={6} sm={12} xs={12}>
            <Card className="report-card" sx={{ background: 'linear-gradient(180deg, #ffffff 0%, ' + color + ' 100%)', border: '1px solid ' + color }}>
                <CardContent sx={{ textAlign: 'left' }}>
                    <GroupOutlinedIcon sx={{ color: color, fontSize: 60 }} />
                    <Typography variant="h5" textAlign="left" color="#ffffff">{reportType.title}</Typography>
                    <hr />
                    <List >
                        {renderReports()}
                    </List>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default ReportTypeCard;