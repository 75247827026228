import { StarRateOutlined } from "@mui/icons-material";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_CONFIGURATIONS } from "../apiConfigurations";

let baseUrl = API_CONFIGURATIONS.AUTH_USER;
const apiEndpoint = baseUrl;

let config = {
    mode: "no-cors",
    headers: {
        "Access-Control-Allow-Origin": "http://localhost:3000",
    },
};

export const userLogin = createAsyncThunk(
    "user/login",
    async({ username, password, applicationUrl, appVersion, authType }, { rejectWithValue }) => {
        try {
            const { data } = await axios.post(
                apiEndpoint, { username, password, appUrl: applicationUrl, appVersion, authType },
                config
            );
            if (data.token) {
                localStorage.setItem("userToken", data.token);
                localStorage.setItem("applicationUrl", applicationUrl);
                localStorage.setItem("appVersion", appVersion);
                //axios.defaults.headers.common['xAccessToken'] = data.token;
                axios.interceptors.request.use(
                  function (config) { 
                    config.headers.xAccessToken = data.token;
                    console.log(config);
                    return config;
                  },
                  function (error) { 
                    return Promise.reject(error);
                  }
                );


                return data;
            } else {
                return rejectWithValue(data.error);
            }
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

const userToken = localStorage.getItem("userToken") ?
    localStorage.getItem("userToken") :
    null;

export const initialState = {
    loading: false,
    userInfo: null,
    userToken,
    error: null,
    success: false,
};

const authUserSlice = createSlice({
    name: "authUser",
    initialState,
    reducers: {
        logout: (state) => {
            localStorage.removeItem("userToken");
            state.loading = false;
            state.userInfo = null;
            state.userToken = null;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(userLogin.pending, (state, action) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(userLogin.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.userInfo = payload;
                state.userToken = payload.token;
            })
            .addCase(userLogin.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            });
    },
});

export const { logout } = authUserSlice.actions;
export default authUserSlice.reducer;