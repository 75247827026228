import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import axios from 'axios';
import swal from "sweetalert2";
import { API_CONFIGURATIONS } from "../apiConfigurations";

let baseUrl = API_CONFIGURATIONS.CATEGORY;
const apiEndpoint = baseUrl;
const accessToken = localStorage.getItem('userToken');

let config = {
    mode: 'no-cors',
    headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        'xAccessToken': accessToken
    }
}

export const initialState = {
    permissionSetCategories: [],
    status: 'idle',
    error: null
}

export const fetchPermissionSetCategories = createAsyncThunk('permissionSetCategory/fetchPermissionSetCategories', async() => {
    const response = await axios.get(apiEndpoint, config)
    const filteredData = response.data.filter(data => data.name != null);
    return filteredData;
})

export const addNewPermissionSetCategory = createAsyncThunk('permissionSetCategory/addNewPermissionSetCategory', async({ data, showStatus }) => {
    try {
        const response = await axios.post(baseUrl, data, config);
        if (response.status === 200) {
            showStatus({ open: true, severity: "success", message: "Permission set category added successfully" });

            return response.data;
        } else {
            showStatus({ open: true, severity: "error", message: "Permission set category adding failed" })
        }
    } catch (error) {
        swal.fire({
            title: 'This category already exists',
            text: "The category you're trying to create already exists in the system. Please choose a different name for your category.",
            icon: 'error',
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#d33',
        })

        //   let errorMessage = "An error occurred";

        //   if (error.response && error.response.data) { 
        //     const errorResponse = error.response.data;
        //     if (errorResponse.includes("System.Exception:")) {
        //       const lines = errorResponse.split("\r\n");
        //       const errorMessageLine = lines.find(line => line.startsWith("System.Exception:"));
        //       if (errorMessageLine) {
        //         const startIndex = errorMessageLine.indexOf("System.Exception:") + "System.Exception:".length;
        //         errorMessage = errorMessageLine.slice(startIndex).trim();
        //       }
        //     }
        //   }

        //   showStatus({ open: true, severity: "error", message: errorMessage });
        //   showStatus({ open: true, severity: "error", message: "This category already exists" });

        throw error;
    }
});



export const updatePermissionSetCategory = createAsyncThunk('permissionSetCategory/updatePermissionSetCategory', async({ data, showStatus }) => {
    const response = await axios.put(baseUrl, data, config);
    if (response.status === 200) {
        showStatus({ open: true, severity: "success", message: "Permission set category updated successfully" })
    } else {
        showStatus({ open: true, severity: "error", message: "Permission set category update failed" })
    }
    return response.data;
})

export const deletePermissionSetCategory = createAsyncThunk('permissionSetCategory/deletePermissionSetCategory', async({ id, showStatus }) => {
    const response = await axios.delete(`${baseUrl}/?ObjKey=${id}`, config);
    console.log("Response a ", response)
        // if (response.status === 200) {
        //     showStatus({ open: true, severity: "success", message: "Permission set category deleted successfully" })
        // } else {
        //     showStatus({ open: true, severity: "error", message: "Permission set category delete failed" })
        // }
    return response.data;
})
const permissionSetCategorySlice = createSlice({
    name: 'permissionSetCategory',
    initialState,
    reducers: {
        addPermissionSetCategory: {
            reducer(state, action) {
                state.permissionSetCategories.push(action.payload);
            }
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchPermissionSetCategories.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchPermissionSetCategories.fulfilled, (state, action) => {
                state.status = 'succeeded'
                    // Add any fetched posts to the array
                state.permissionSetCategories = action.payload
            })
            .addCase(fetchPermissionSetCategories.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(addNewPermissionSetCategory.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(addNewPermissionSetCategory.fulfilled, (state, action) => {
                state.status = 'succeeded'

                state.permissionSetCategories = state.permissionSetCategories
                    .concat(...action.payload)
                    .sort((a, b) => {
                        const nameA = a.name.toUpperCase();
                        const nameB = b.name.toUpperCase();
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0;
                    });
            })
            .addCase(addNewPermissionSetCategory.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(updatePermissionSetCategory.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(updatePermissionSetCategory.fulfilled, (state, action) => {
                state.status = 'succeeded'
                    // const categories = [...current(state.permissionSetCategories)]
                    // const index = categories.findIndex(cat => cat.name === action.payload.name);
                    // console.log("Payload ", action.payload)
                    // categories[index] = action.payload;
                    // console.log("Payload ", categories)
                    // state.permissionSetCategories = categories;

                state.permissionSetCategories = action.payload;
            })
            .addCase(updatePermissionSetCategory.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(deletePermissionSetCategory.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(deletePermissionSetCategory.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(deletePermissionSetCategory.fulfilled, (state, action) => {
                state.status = 'succeeded'

                console.log("DELETE ACRION RESPONSE ", action)
            })
    }
});

export const { addPermissionSetCategory } = permissionSetCategorySlice.actions;

export default permissionSetCategorySlice.reducer

export const selectAllPermissionSetCategories = state => state.permissionSetCategories.permissionSetCategories;

export const selectPermissionSetCategoryById = (state, id) => {
    const cat = state.permissionSetCategories.permissionSetCategories.find(permissionSetCategory => permissionSetCategory.objkey === id);
    return cat;
};