import { Alert, Autocomplete, Button, Grid, LinearProgress, Snackbar, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import {
    selectAllPermissionSetCategories,
    fetchPermissionSetCategories
} from "../../../redux/reducers/permissionSetCategorySlice";
import { addNewEndUserRole, selectEndUserRoleByName, selectAllEndUserRoles, fetchEndUserRoles, updateEndUserRole } from "../../../redux/reducers/endUserRoleSlice";

const PermissionUpdate = () => {
    const { permissionName, action } = useParams();
    const [loading, setLoading] = useState(permissionName !== "new");
    const [permissionData, setPermissionData] = useState({});
    const [snakebarOps, setSnakebarOps] = useState({ open: false, severity: "", message: "" });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const permissionSetCategories = useSelector(selectAllPermissionSetCategories);
    const permissionSetCategoryStatus = useSelector(state => state.permissionSetCategories.status);
    const duplicateRole = useSelector(state => selectEndUserRoleByName(state, permissionName))
    const endUserRoleStatus = useSelector(state => state.endUserRoles.status);

    useEffect(() => {
        if (permissionSetCategoryStatus === 'idle') {
            dispatch(fetchPermissionSetCategories());
        }
        if (endUserRoleStatus === 'idle') {
            dispatch(fetchEndUserRoles());
        }
        if (action === 'duplicate' && duplicateRole) {
            const data = {
                ...duplicateRole,
                name: duplicateRole.name + '_COPY'
            }
            setPermissionData(data)
        } else if (permissionName && permissionName !== 'new') {
            setPermissionData(duplicateRole);
        }
    }, [permissionName, permissionSetCategories, endUserRoleStatus, dispatch, permissionSetCategoryStatus, action, duplicateRole]);


    const handleChange = (field, value) => {
        const updatedValue = {};
        updatedValue[field] = value;

        switch (field) {
            case 'name':
                setPermissionData({
                    ...permissionData,
                    ...updatedValue
                })
                return;
            case 'description':
                setPermissionData({
                    ...permissionData,
                    ...updatedValue
                })
                return;
            case 'category':
                setPermissionData({
                    ...permissionData,
                    ...updatedValue
                })
                return;
            default:
                console.log("No case defined");
                return;
        }
    }

    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
    };

    const onSubmit = () => {
        return permissionName === "new"
            ? createPermissionSet()
            : action === "duplicate"
                ? createPermissionSet()
                : updatePermissionSet()
    }

    const createPermissionSet = async () => {
        const newPermission = [{
            "name": permissionData.name,
            "description": permissionData.description,
            "category": permissionData.category
        }]

        dispatch(addNewEndUserRole({ data: newPermission, showStatus: setSnakebarOps })).then(response => {
            if (response.type === "endUserRoles/addEndUserRole/fulfilled") {
                navigate(-1)
            }
        });
    }

    const updatePermissionSet = async () => {
        const updatedPermission = [{
            "name": permissionData.name,
            "description": permissionData.description,
            "category": permissionData.category
        }]

        dispatch(updateEndUserRole({ data: updatedPermission, showStatus: setSnakebarOps })).then(response => {
            if (response.type === "endUserRoles/updateEndUserRole/fulfilled") {
                navigate(-1)
            }
        })

        // const rawResponse = await fetch(process.env.REACT_APP_BASE_API_URL + '/EndUserPermissionSet', {
        //     method: 'PUT',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json',
        //         'xAccessToken': localStorage.getItem('userToken')
        //     },
        //     body: JSON.stringify(updatedPermission)
        // });

        // if (rawResponse.status === 200) {
        //     setSnakebarOps({
        //         open: true,
        //         severity: 'success',
        //         message: 'Updated successfully!'
        //     })

        //     setTimeout(() => {
        //         navigate(-1);
        //     }, 2500)
        // } else {
        //     setSnakebarOps({
        //         open: true,
        //         severity: 'error',
        //         message: 'Error updating permission set!'
        //     })
        // }
    }
    console.log("P DATA ", permissionSetCategories, permissionData?.category)
    return (
        <div>
            <Snackbar open={snakebarOps.open} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} severity={snakebarOps.severity} autoHideDuration={2000} onClose={() => setSnakebarOps({ open: false })}>
                <Alert onClose={() => setSnakebarOps({ open: false })} severity={snakebarOps.severity} sx={{ width: '100%' }}>
                    {snakebarOps.message}
                </Alert>
            </Snackbar>
            {endUserRoleStatus === "loading" ? <LinearProgress sx={{ m: 50 }} color="inherit" /> :
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "left" }}>
                        <h2 className="page-title">{permissionName === "new" ? "Create New" : action === "duplicate" ? "Duplicate" : "Edit"} End-User Role</h2>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12} gutter={4}>
                        <Stack spacing={2}>
                            <TextField
                                value={permissionData?.name}
                                fullWidth
                                size="small"
                                label="Name"
                                disabled={permissionName !== "new" && action !== "duplicate"}
                                name="Name"
                                onChange={(e) => handleChange("name", e.target.value)}
                                onInput={toInputUppercase}
                            />
                            <TextField
                                value={permissionData?.description}
                                fullWidth
                                size="small"
                                label="Description"
                                name="description"
                                rows={4}
                                onChange={(e) => handleChange("description", e.target.value)}
                            />
                            {(permissionData.category !== undefined || permissionName === 'new') && <Autocomplete
                                value={permissionData?.category}
                                id="category"
                                size="small"
                                //disableClearable
                                options={permissionSetCategories.map(category => category.name)}
                                onChange={(event, value) => handleChange("category", value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Category"
                                        InputProps={{
                                            ...params.InputProps
                                        }}
                                    />
                                )}
                            />}
                            <Stack direction='row' spacing={2}>
                                <Button
                                    variant="outlined"
                                    color="warning"
                                    onClick={() => navigate(-1)}
                                    sx={{ flex: 1 }}
                                    disableElevation
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{ flex: 1 }}
                                    disableElevation
                                    onClick={() => onSubmit()}
                                >
                                    Save
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>}
        </div>
    )
}

export default PermissionUpdate;