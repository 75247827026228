import { Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, Button } from "@mui/material"

const ActionStatusDialog = ({ actionStatus, handleConfirm, handleClose }) => {
    const {showStatus, title, message, agreeMsg="OK"} = actionStatus;
    
    return (
        <Dialog
            open={showStatus}
            onClose={handleClose}
            fullWidth
            maxWidth='xs'
        >
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" size="small" onClick={handleClose}>Cancel</Button>
                <Button variant="contained" color='error' size="small" onClick={handleConfirm} autoFocus>
                    {agreeMsg}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ActionStatusDialog;