import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import { API_CONFIGURATIONS } from "../apiConfigurations";

let baseUrl = API_CONFIGURATIONS.FUNCTION_TO_END_USER_ROLES;;
const apiEndpoint = baseUrl;
const accessToken = localStorage.getItem('userToken');

let config = {
    mode: 'no-cors',
    headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        'xAccessToken': accessToken
    }
}

export const initialState = {
    functionalToEndUserMapping: [],
    functionalToEndUserMappingLvTwo: [],
    functionalToEndUserMappingLvThree: [],
    status: 'idle',
    addMappingStatus: 'idle',
    error: null
}

export const fetchfunctionalToEndUserMapping = createAsyncThunk('functionalToEndUserMapping/fetchfunctionalToEndUserMapping', async() => {
    const response = await axios.get(apiEndpoint, config)
    return response.data;
})

export const fetchChildLevelMapping = createAsyncThunk('functionalToEndUserMapping/fetchChildLevelMapping', async({ level, root, parent }) => {
    const childURL = baseUrl + '?root=' + root + '&parent=' + parent;
    const response = await axios.get(childURL, config)
    console.log("FETCHING Childs", response.data)
    return { data: response.data, level };
})

export const addChildToMapping = createAsyncThunk('functionalToEndUserMapping/addChildToMapping', async({ level, data }) => {
    console.log("Add Mapping levels ", level, data);
    const response = await axios.post(baseUrl, data, { config })
    console.log("Response test", response);
    return { data: response.data, level };
})

const functionalToEndUserMappingSlice = createSlice({
    name: 'functionalToEndUserMapping',
    initialState,
    reducers: {
        addfunctionalToEndUserMapping: {
            reducer(state, action) {
                state.functionalToEndUserMapping.push(action.payload);
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchfunctionalToEndUserMapping.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchfunctionalToEndUserMapping.fulfilled, (state, action) => {
                state.status = 'succeeded'
                    // Add any fetched posts to the array
                state.functionalToEndUserMapping = state.functionalToEndUserMapping.concat(action.payload)
            })
            .addCase(fetchfunctionalToEndUserMapping.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(fetchChildLevelMapping.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchChildLevelMapping.fulfilled, (state, action) => {
                state.status = 'succeeded'
                console.log("TEST ", action)
                switch (action.payload.level) {
                    case 1:
                        {
                            console.log("Loading Level One", action.payload.data)
                            state.functionalToEndUserMappingLvTwo = action.payload.data;
                            return;
                        }
                    case 2:
                        {
                            console.log("Loading Level two", action.payload.data)
                            state.functionalToEndUserMappingLvThree = action.payload.data;;
                            return;
                        }
                    default:
                        {
                            console.log("No level defined");
                        }
                }

            })
            .addCase(fetchChildLevelMapping.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(addChildToMapping.pending, (state, action) => {
                state.addMappingStatus = 'loading'
            })
            .addCase(addChildToMapping.fulfilled, (state, action) => {
                state.addMappingStatus = 'succeeded'
                switch (action.payload.level) {
                    case 2:
                        {
                            console.log("Adding Level Two", action.payload.data)
                            state.functionalToEndUserMappingLvTwo = action.payload.data;
                            return;
                        }
                    case 3:
                        {
                            console.log("Loading Level Three", action.payload.data)
                            state.functionalToEndUserMappingLvThree = action.payload.data;
                            return;
                        }
                    default:
                        {
                            console.log("No level defined");
                        }
                }
            })
            .addCase(addChildToMapping.rejected, (state, action) => {
                state.addMappingStatus = 'failed'
                state.error = action.error.message
            })
    }
});

export const { addfunctionalToEndUserMapping } = functionalToEndUserMappingSlice.actions;

export default functionalToEndUserMappingSlice.reducer

export const selectAllfFunctionalToEndUserMapping = state => state.functionalToEndUserMapping.functionalToEndUserMapping;

export const selectFunctionalToEndUserMappingLvTwo = state => state.functionalToEndUserMapping.functionalToEndUserMappingLvTwo;

export const selectFunctionalToEndUserMappingLvThree = state => state.functionalToEndUserMapping.functionalToEndUserMappingLvThree;

export const selectFunctionalToEndUserMappingById = (state, id) =>
    state.functionalToEndUserMapping.functionalToEndUserMapping.find(mapping => mapping.id === id);