import { Grid, Typography } from "@mui/material"
import { type } from "@testing-library/user-event/dist/type";
import ReportTypes from "../../helpers/datasource/reportTypes";
import ReportTypeCard from "../../components/ReportTypeCard";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const ReportsDashbord = () => {
    const location = useLocation();

    useEffect(() => {
      const currentPath = location.pathname;
      localStorage.setItem("previousPath", currentPath);
    }, [location.pathname]);

    const renderReportGrid = () => {
        const reportGrid = [];
        ReportTypes.forEach(reportType => {
            reportGrid.push(<ReportTypeCard reportType={reportType} />)
        })

        return reportGrid;
    }
    return (
        <Grid container spacing={4}>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "left" }}>
                <h2 className="page-title">Reports</h2>
            </Grid>
            {renderReportGrid()}
        </Grid>
    )
}

export default ReportsDashbord;