import React from "react";
import CheckCircle from "@mui/icons-material/CheckCircle";
import RadioButtonChecked from "@mui/icons-material/RadioButtonChecked";

const Legend = () => {
  return (
    <div className="legend-container">
      <h3 className="legend-title">Legend:</h3>

      <div className="legend-item">
        <CheckCircle style={{ color: "#000" }} />
        <span className="legend-text">
          Navigator level <strong>completely</strong> granted with full grant
          (read & write)
        </span>
      </div>

      <div className="legend-item">
        <CheckCircle style={{ color: "#4461d4" }} />
        <span className="legend-text">
          Navigator level <strong>completely</strong> granted with read only
          grant
        </span>
      </div>

      <br></br>

      <div className="legend-item">
        <RadioButtonChecked style={{ color: "#000" }} />
        <span className="legend-text">
          Navigator level <strong>partially</strong> granted with full grant
          (read & write)
        </span>
      </div>

      <div className="legend-item">
        <RadioButtonChecked style={{ color: "#4461d4" }} />
        <span className="legend-text">
          Navigator level <strong>partially</strong> granted with read only
          grant
        </span>
      </div>
    </div>
  );
};

export default Legend;
