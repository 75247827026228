const TemplateTypes = [
    {
        id: 'rep001',
        title: 'Manufacturing',
        icon: '',
        color: '#d4620a',
        templates: [
            {
                id: 'rep001_1',
                title: 'Template 1',
                icon: '',
                target: '../helpers/files/Aviation_and_Defense_Permissions_Template_1.zip',
            },
            {
                id: 'rep001_1',
                title: 'Template 2',
                icon: '',
                target: '../helpers/files/Aviation_and_Defense_Permissions_Template_1.zip',
            }
        ]
    },
    {
        id: 'rep002',
        title: 'Supply Chain',
        icon: '',
        color: '#06604b',
        templates: [
            {
                id: 'rep001_1',
                title: 'Template 1',
                icon: '',
                target: '../helpers/files/Aviation_and_Defense_Permissions_Template_1.zip',
            },
            {
                id: 'rep001_1',
                title: 'Template 2',
                icon: '',
                target: '../helpers/files/Aviation_and_Defense_Permissions_Template_1.zip',
            }
        ]
    },
    {
        id: 'rep003',
        title: 'Aviation and Defence',
        icon: '',
        color: '#074d89',
        templates: [
            {
                id: 'rep001_1',
                title: 'Template 1',
                icon: '',
                target: '../helpers/files/Aviation_and_Defense_Permissions_Template_1.zip',
            },
            {
                id: 'rep001_1',
                title: 'Template 2',
                icon: '',
                target: '../helpers/files/Aviation_and_Defense_Permissions_Template_1.zip',
            }
        ]
    },
    {
        id: 'rep004',
        title: 'MRO & Fleet Operation',
        icon: '',
        color: '#2d0789',
        templates: [
            {
                id: 'rep001_1',
                title: 'Template 1',
                icon: '',
                target: '../helpers/files/Aviation_and_Defense_Permissions_Template_1.zip',
            },
            {
                id: 'rep001_1',
                title: 'Template 2',
                icon: '',
                target: '../helpers/files/Aviation_and_Defense_Permissions_Template_1.zip',
            }
        ]
    },
    {
        id: 'rep005',
        title: 'Retail',
        icon: '',
        color: '#890719',
        templates: [
            {
                id: 'rep001_1',
                title: 'Template 1',
                icon: '',
                target: '../helpers/files/Aviation_and_Defense_Permissions_Template_1.zip',
            },
            {
                id: 'rep001_1',
                title: 'Template 2',
                icon: '',
                target: '../helpers/files/Aviation_and_Defense_Permissions_Template_1.zip',
            }
        ]
    }
]

export default TemplateTypes;