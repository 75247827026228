const ReportTypes = [{
        id: 'rep001',
        title: 'User Activities',
        icon: '',
        color: '#d4620a',
        reports: [{
            id: 'rep001_1',
            title: 'User Reports',
            icon: '',
            target: '/reports/users',
        }]
    },
    {
        id: 'rep002',
        title: 'Permission Analysis',
        icon: '',
        reports: [{
            id: 'rep002_1',
            title: 'Navigator to Functional Permission Sets Reports',
            icon: '',
            target: '/reports/permissions/nfps',
        }, {
            id: 'rep002_2',
            title: 'End-User Role Breakdown Reports',
            icon: '',
            target: '/reports/permissions/eurb',
        }]
    }
]

export default ReportTypes;