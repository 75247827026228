import './App.css';
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom';
import AppLayout from './layouts/AppLayout';
import store from "./redux/store";

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <div className="App">
          <AppLayout />
        </div>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
