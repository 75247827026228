import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";
import { API_CONFIGURATIONS } from "../apiConfigurations";

let baseUrl = API_CONFIGURATIONS.USER_TO_END_USER_ROLES;
const apiEndpoint = baseUrl;
const accessToken = localStorage.getItem("userToken");

let config = {
  mode: "no-cors",
  headers: {
    "Access-Control-Allow-Origin": "http://localhost:3000",
    xAccessToken: accessToken,
  },
};

export const initialState = {
  usersToEndUserRole: [],
  status: "idle",
  error: null,
};

export const fetchUsersToEndUserRoleMapping = createAsyncThunk(
  "usersToEndUserRole/fetchUsersToEndUserRoleMapping",
  async () => {
    const response = await axios.get(apiEndpoint, config);
    return response.data;
  }
);

const usersToEndUserRoleSlice = createSlice({
  name: "usersToEndUserRole",
  initialState,
  reducers: {
    updateMappingMatrix: {
      reducer(state, action) {
        const { data, endUserRole, type } = action.payload;
        const usersToEndUserRole = [...current(state.usersToEndUserRole)];
        const matrixIndex = usersToEndUserRole.findIndex(
          (matrix) => matrix.user === data.user
        );
        const updatedMapping = { ...usersToEndUserRole[matrixIndex] };
        let arrayUpdate = [...updatedMapping.endUserPermissionSets];

        if (type === "add") {
          arrayUpdate.push(endUserRole);
        } else {
          arrayUpdate.splice(
            arrayUpdate.findIndex((elem) => elem.name === endUserRole.name),
            1
          );
        }

        updatedMapping.endUserPermissionSets = arrayUpdate;
        usersToEndUserRole[matrixIndex] = updatedMapping;

        return {
          ...state,
          usersToEndUserRole,
        };
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUsersToEndUserRoleMapping.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchUsersToEndUserRoleMapping.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched posts to the array
        state.usersToEndUserRole = action.payload;
      })
      .addCase(fetchUsersToEndUserRoleMapping.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateMappings.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateMappings.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateMappings.fulfilled, (state, action) => {
        state.status = "succeeded";
        const usersToEndUserRole = [...state.usersToEndUserRole];
        console.log("state updated started");
        action.payload.forEach((updatedObject) => {
          const mappingIndex = usersToEndUserRole.find(
            (mapping) => mapping.user === updatedObject.user
          );
          usersToEndUserRole[mappingIndex] = updatedObject;
        });
        state.usersToEndUserRole = usersToEndUserRole;
        console.log("state updated finished");
      });
  },
});

export const updateMappings = createAsyncThunk(
  "usersToEndUserRole/updateMappings",
  async ({ data, closePopup }) => {
    // console.log("reached updating async function");
    // const response = await new Promise((resolve) => {
    //   setTimeout(() => {
    //     // Mock a successful response object
    //     const mockResponse = {
    //       status: 404,
    //       data: data, // Return the same data that was sent in the request
    //     };
    //     resolve(mockResponse);
    //   }, 5000);
    // });
    const response = await axios.post(baseUrl, data, config);

    // console.log("finished axios updating async function");
    if (response.status === 200) {
      // showStatus({ open: true, severity: "success", message: "Mapping added successfully" })
      setTimeout(() => {
        closePopup();
        console.log("success response");
      }, 500);
    } else {
      // showStatus({ open: true, severity: "error", message: "Mapping adding failed" })
      setTimeout(() => {
        closePopup();
        console.log("error response");
      }, 500);
    }
    // console.log("returning response data");
    return response.data;
  }
);

export const { updateMappingMatrix } = usersToEndUserRoleSlice.actions;

export default usersToEndUserRoleSlice.reducer;

export const selectAllUsersToEndUserRole = (state) =>
  state.usersToEndUserRole.usersToEndUserRole;
