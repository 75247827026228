import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import axios from 'axios';
import { API_CONFIGURATIONS } from "../apiConfigurations";

let baseUrl = API_CONFIGURATIONS.GET_PARENT_NAVIGATOR_TO_FUNCTIONAL_PERMISSION_SETS;
const apiEndpoint = baseUrl;
const accessToken = localStorage.getItem('userToken');

let config = {
    mode: 'no-cors',
    headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        'xAccessToken': accessToken
    }
}


export const initialState = {
    navigatorToFunctionalPermissionSets: [],
    status: 'idle',
    error: null
}

export const fetchNavigatorToFunctionalPermissionSets = createAsyncThunk('navigatorToFunctionalPermissionSets/fetchNavigatorToFunctionalPermissionSets', async() => {
    const response = await axios.get(apiEndpoint + '-2', config);
    return response.data;
})

export const fetchChildNodes = createAsyncThunk('navigatorToFunctionalPermissionSets/fetchChildNodes', async({ id }) => {
    const childUrl = apiEndpoint + '25420334';
    const response = await axios.get(childUrl, config);
    return { data: response.data, id };
})

const navigatorToFunctionalPermissionSetsSlice = createSlice({
    name: "navigatorToFunctionalPermissionSets",
    initialState,
    reducers: {

    },
    extraReducers(builder) {
        builder
            .addCase(fetchNavigatorToFunctionalPermissionSets.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchNavigatorToFunctionalPermissionSets.fulfilled, (state, action) => {
                state.status = 'succeeded'
                console.log("Action ", action)
                    // Add any fetched posts to the array
                state.navigatorToFunctionalPermissionSets = state.navigatorToFunctionalPermissionSets.concat(action.payload)
            })
            .addCase(fetchNavigatorToFunctionalPermissionSets.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(fetchChildNodes.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchChildNodes.fulfilled, (state, action) => {
                const { data, id } = action.payload;
                state.status = 'succeeded';
                const stateCopy = [...current(state.navigatorToFunctionalPermissionSets)];
                const fetchIndex = stateCopy.findIndex(record => record.id === id);
                if (fetchIndex !== -1) {
                    stateCopy[fetchIndex] = {...stateCopy[fetchIndex], type: data }
                } else {
                    for (let index in stateCopy) {
                        const levelOneIndex = stateCopy[index].type.findIndex(record => record.id === id);
                        console.log("levelOneIndex", levelOneIndex, levelOneIndex !== -1)
                        if (levelOneIndex !== -1) {
                            console.log("levelOneIndex A", stateCopy[index].type[levelOneIndex])
                            const levelOneCopy = {...stateCopy[index].type[levelOneIndex], type: data };
                            console.log("levelOneIndex B", levelOneCopy)
                            stateCopy[index].type[levelOneIndex] = levelOneCopy;
                            console.log("levelOneIndex C", stateCopy[index].type[levelOneIndex])

                        } else {
                            console.log("Else running levelOneIndex")
                        }
                    }
                }

                state.navigatorToFunctionalPermissionSets = stateCopy
            })
    }
})

const getUpdatedObject = (id, data, children) => {
    data.forEach(record => {
        if (record.id === id) {
            record = {...record, type: children }
            return;
        } else {
            if (record.type.length > 0) {
                record.type.forEach(record => {
                    if (record.id === id) {
                        record = {...record, type: children }
                        console.log("Record ", record)
                        return;
                    }
                })
            }
        }
    })
}

export default navigatorToFunctionalPermissionSetsSlice.reducer;

export const selectAllNavigatorToFunctionalPermissionSets = state => state.navigatorToFunctionalPermissionSets.navigatorToFunctionalPermissionSets;