import * as React from "react";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { updateMappings } from "../../redux/reducers/functionalToEndUserMatrixSlice";
import { useState } from "react";
import { useDispatch } from "react-redux";

const UpdateConfirmationDialog = ({
  openConfirmation = false,
  handleConfirmationClose,
  type,
  updatedData = [],
  handleSave,
  setOpenConfirmation,
  handleCancel,
  handleClose,
  handleCancelCell,
}) => {
  const dispatch = useDispatch();
  const [isUpdating, setUpdating] = useState(false);
  const [showSavePopUp, setSavePopUp] = useState(true);

  const handleCloseWithSave = () => {
    setUpdating(false);
    handleClose();
  };

  const handleCloseWithCancel = () => {
    setOpenConfirmation(false);
  };

  const renderChanges = () => {
     
    const structure = [];
    updatedData.length > 0 &&
      updatedData
      .filter((mapping) => {
        return !(mapping.functionalPermissionSetsAdd.length === 0 && mapping.functionalPermissionSetsDel.length === 0);
      })
      .map((data) => {
        structure.push(
          <h4 style={{ marginTop: 15, marginBottom: 5 }}>{data.endUserRole}</h4>
        );
         
        data.functionalPermissionSetsAdd &&
          data.functionalPermissionSetsAdd.forEach((permission) =>
            structure.push(
              <Chip label={permission} size="small" color="success" />
            )
          );
        data.functionalPermissionSetsDel &&
          data.functionalPermissionSetsDel.forEach((permission) =>
            structure.push(
              <Chip label={permission} size="small" color="error" />
            )
          );
      });
    return structure;
  };

  const onClickSave = async () => {
    setSavePopUp(false);
    handleClose();
    //setOpenConfirmation(false);
     
     const filteredData = updatedData.filter((mapping) => {
    return !(mapping.functionalPermissionSetsAdd.length === 0 && mapping.functionalPermissionSetsDel.length === 0);
  });

    dispatch(
      updateMappings({ data: filteredData, closePopup: handleCloseWithSave })
    );
  };

  return isUpdating ? (
    <>
      <Dialog open={openConfirmation} onClose={handleConfirmationClose}>
        {/* ... rest of your Dialog content */}
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }}
        open={isUpdating}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  ) : (
    <Dialog open={openConfirmation} onClose={handleConfirmationClose}>
      <DialogTitle>Are you sure?</DialogTitle>
      <div style={{ padding: "0px 25px" }}>{renderChanges()}</div>
      <DialogContent>
        <DialogContentText>
          {type === "save"
            ? "You want to save the changes you made?"
            : "Abort the changes you made?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCloseWithCancel()}>Cancel</Button>
        <Button onClick={() => onClickSave()}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateConfirmationDialog;
