import {
  Box,
  Button,
  Grid,
  FormControl,
  Stack,
  styled,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import background from "../../static/images/login-bg.jpg";
import logo from "../../static/images/logo-colored.png";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../redux/reducers/authUserSlice";
import { useState } from "react";
import { useEffect } from "react";

const ImageLeft = styled(Box)(({ theme }) => ({
  height: "100vh",
  backgroundImage: "url(" + background + ")",
  backgroundSize: "cover",
  backgroundPosition: "center",
  "&:hover": {
    filter: "brightness(0.75)",
  },
}));

const SignIn = () => {
  const { loading, error, userInfo } = useSelector((state) => state.authUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] = useState({
    applicationUrl: "",
    username: "",
    password: "",
    appVersion: "",
    //authType: "",
  });

  const onSignIn = () => {
    dispatch(userLogin(formData));
  };

    useEffect(() => {
      const currentPath = location.pathname;
      localStorage.setItem("previousPath", currentPath);
    }, [location.pathname]);

  useEffect(() => {
    if (userInfo) {
      navigate("/functional-permission-sets");
    }
  }, [navigate, userInfo]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item md={8} sx={{ flexGrow: 1 }}>
          <ImageLeft />
        </Grid>
        <Grid item md={4}>
          <Stack
            alignItems="center"
            spacing={2}
            mx={"5vw"}
            height="80vh"
            justifyContent="center"
          >
            <Box mb={2}>
              <>
                <img src={logo} height="50px"></img>
              </>
              {/* <Typography
                variant="h5"
                sx={{ color: "#121f47", fontWeight: 600 }}
              >
                PERMISSION TOOL{" "}
                <span style={{ fontWeight: 400, fontSize: 14 }}> v0.2</span>
              </Typography> */}
            </Box>
            <TextField
              onChange={(e) =>
                setFormData({ ...formData, applicationUrl: e.target.value })
              }
              sx={{ marginTop: 2 }}
              value={formData.applicationUrl}
              placeholder="Application URL"
              variant="outlined"
              label="Application URL"
              size="small"
              fullWidth
              error={error}
            />
            <TextField
              onChange={(e) =>
                setFormData({ ...formData, username: e.target.value })
              }
              sx={{ marginTop: 2 }}
              variant="outlined"
              label="Username"
              size="small"
              fullWidth
              error={error}
            />
            <TextField
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
              sx={{ marginTop: 2 }}
              variant="outlined"
              label="Password"
              type="password"
              size="small"
              fullWidth
              error={error}
              helperText={error}
            />
            <FormControl fullWidth size="small">
              <InputLabel id="func-appv-label"> App Version</InputLabel>
              <Select
                fullWidth
                label="App Version"
                labelId="func-appv-label"
                size="small"
                value={formData.appVersion}
                style={{ textAlign: "left" }}
                onChange={(e) =>
                  setFormData({ ...formData, appVersion: e.target.value })
                }
                error={error}
              >
                {["APPS10", "23R1"].map((appv) => (
                  <MenuItem key={appv} value={appv}>
                    {appv}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <FormControl fullWidth size="small">
              <InputLabel id="func-auth-label"> Authorization Type</InputLabel>
              <Select
                fullWidth
                label="Authorization Type"
                labelId="func-auth-label"
                size="small"
                value={formData.authType}
                style={{ textAlign: "left" }}
                onChange={(e) =>
                  setFormData({ ...formData, authType: e.target.value })
                }
                error={error}
              >
                {["Basic", "Bearer"].map((appv) => (
                  <MenuItem key={appv} value={appv}>
                    {appv}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
            <Button
              onClick={() => onSignIn()}
              variant="contained"
              fullWidth
              size="medium"
              sx={{ marginTop: 2 }}
            >
              Sign In
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SignIn;
