import { Table } from "antd";
import { CheckCircle, Cancel } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";

const DataTable = ({
  endUserRoles= [],
  loading,
  data= [],
  filters,
  onClickCell,
  clearRoute,
}) => {
  const navigate = useNavigate();

  const { name } = useParams();
  if (name != undefined) {
    filters.filterTextRoles = name; 
  }

  const [height, setHeight] = useState(0);
  const ref = useRef(this);
 
  const updateHeight = () => {
    if (ref.current) { 
      const newHeight = ref.current.clientWidth;
      setHeight(newHeight); 
    }
  };

  useLayoutEffect(() => {
    updateHeight();   
    window.addEventListener("resize", updateHeight); 
    return () => window.removeEventListener("resize", updateHeight);
  }, []);  

  const renderColumns = () => {
    
    let filteredData = endUserRoles;
    const columns = [
      {
        title: "",
        dataIndex: "user",
        key: "user",
        fixed: "left",
        className: "normal-header",
        render: (a, data) => {
        
          return {
            children: data.user,
            props: {
              style: {
                minWidth: "150px",
                width: "150px",
                maxHeight: "40px",
                height: "40px",
              },
            },
          };
        },
      },
    ];

    filteredData.forEach((endUserRole) => {
      columns.push({
        title: (
          <Tooltip title={endUserRole.description} arrow placement="top">
            <div className="inner-box">{endUserRole.name}</div>
          </Tooltip>
        ),
        dataIndex: "endUserPermissionSets",
        key: "endUserPermissionSets",
        render: (endUserPermissionSets, data) => { const status = endUserPermissionSets && endUserPermissionSets.find( (value) => value.name === endUserRole.name  ) !== undefined;
          return {
            props: {
              style: {
                backgroundColor: status && "#06B6A2",
                textAlign: "left",
                maxWidth: "40px",
                minWidth: "40px",
                width: "40px",
                minHeight: "40px",
                paddingTop: "5px",  
                paddingBottom: "0px",  
              },
              onClick: () =>
                onClickCell(data, endUserRole, status ? "remove" : "add"),
            },
            children: status && <CheckCircle />,
          };
        },
        className: "hover",
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              column?.title &&  navigate(  `/functional-permission-to-end-user/endUserRoles/${column?.title?.props?.children?.props?.children}` );
            },
          };
        },
        width: 40,
      });
    });

    if (columns.length * 40 + 150 < height) {
      columns.push({
        title: "",
        fixed: "left",
        render: () => {
          return {
            props: {
              style: { minWidth: 1 },
            },
          };
        },
      });
    }

    return columns;
  };

  const columns = renderColumns();

  const getFilteredColumns = () => {
    
    let filteredData = columns; 
 
    if (filters.filterTextRoles && filters.filterTextRoles !== "") {
      
      filteredData = filteredData.filter((column, index) => { 
        if (index === 0) return true;
 
        let titleText;
        if (React.isValidElement(column.title) && column.title.props) {
          titleText = column?.title?.props?.children?.props?.children;
        } else if (typeof column.title === "string") {
          titleText = column.title;
        } else {
          return false;  
        } 

        return (titleText.includes(filters.filterTextRoles) || titleText === "" || titleText === "Actions");
      });
    }

 
   if (filters.endUserCat && filters.endUserCat.length > 0) {
 
    let eurData = endUserRoles;
    let filterData = filteredData; 
    let userColoumn = filterData[0];
    const titlesArray = filterData.map((item) => {
        if (item.title && item.title.props && item.title.props.children) {
           
            return item?.title?.props?.children?.props?.children;
        }
        return null;
    });
 
    const filteredEURData = eurData.filter((item) => titlesArray.includes(item.name)); 
    const finalFilter = filteredEURData.filter((column) => filters.endUserCat.includes(column.category));

    const filteredChildren = finalFilter.map(item => {
        const foundItem = filterData.find(dataItem => dataItem.title && dataItem.title.props && dataItem?.title?.props?.children?.props?.children === item.name);
        return foundItem && foundItem.title && foundItem.title.props && foundItem?.title?.props?.children?.props?.children ? foundItem : null;
    });

    userColoumn = [userColoumn].concat(filteredChildren);
 
    filteredData = userColoumn;
}

    if (filters.activeRolesMappings) {
       
      let filteredRoles = [];
      //let filteredRoles = data.map((item) => item.endUserPermissionSets.map((roleItem) => roleItem.name)).flat();

      // if (filters.filterTextUser && filters.filterTextUser !== "") {
      //   let a = data.filter((col) => col.user === filters.filterTextUser);
      //   filteredRoles = a.map((item) => item.endUserPermissionSets.map((roleItem) => roleItem.name)).flat();
      // }

       if (filters.users && filters.users.length > 0) {
        
        filters.users.forEach(user => { 
          let filteredData = data.filter(col => col.user === user);
          let roles = filteredData.flatMap(item => item.endUserPermissionSets.map(roleItem => roleItem.name));
          filteredRoles.push(...roles);
        }); 
      }else{
        filteredRoles = data.map((item) => item.endUserPermissionSets.map((roleItem) => roleItem.name)).flat();
      }

      filteredData = filteredData.filter((column, index) => { 
        if (index === 0) return true;

        let titleText;
        if (React.isValidElement(column.title) && column.title.props) {
          titleText = column?.title?.props?.children?.props?.children;
        } else if (typeof column.title === "string") {
          titleText = column.title;
        } else {
          return false; 
        }

        return ( filteredRoles.includes(titleText) || titleText === "" || titleText === "Actions" );
      });
    }

    if (filteredData.length * 40 + 150 < height) {
      filteredData.push({
        title: "",
        render: () => {
          return {
            props: {
              style: { minWidth: 1 },
            },
          };
        },
      });
    }
     
    return filteredData;
  };

  const getFilteredRows = (filteredColumns1) => {
    
    let filteredData = data;
    let filteredColumns = filteredColumns1; 

  
    if (filters.userGroups && filters.userGroups.length > 0) {
      filteredData = filteredData.filter((val) => {
        if (Array.isArray(val.userGroup)) {
          return val.userGroup.some((group) =>
            filters.userGroups.includes(group)
          );
        } else {
          return filters.userGroups.includes(val.userGroup);
        }
      });
       
    }

  //   if (filters.filterTextUser && filters.filterTextUser !== "") {
  //     filteredData = filteredData.filter((value) =>  value.user.includes(filters.filterTextUser) );
  //  }

    if (filters.users && filters.users.length > 0) {
       
      let usersFilteredData = [];
      filters?.users?.forEach(user => {
        let filteredDataForUser = filteredData?.filter((value) => value.user === user);
        usersFilteredData.push(...filteredDataForUser);
      });
      filteredData = [...usersFilteredData];
    }


  
    if (filters.activeUserMappings) { 

      const permissionNames = new Set(); 
      filteredColumns.slice(1).forEach((column) => {
        try { 
          if ( column.title && column.title.props &&  column.title.props.children
          ) {
            permissionNames.add(column?.title?.props?.children?.props?.children);
          } else {
            console.log("Error or undefined properties in column: ", column);  
          }
        } catch (error) {
          console.error("Error processing column: ", column, error); 
        }
      });

      // if (
      //   (filters.filterTextRoles && filters.filterTextRoles !== "") ||
      //   filters.activeRolesMappings
      // ) {
      //   // Filter filteredData
      //   filteredData = data.filter((dataItem) => {
      //     return dataItem.endUserPermissionSets.some((permissionSet) => {
      //       return permissionNames.has(permissionSet.name);
      //     });
      //   });
      // }

      filteredData = filteredData.filter( (val) =>  val.endUserPermissionSets && val.endUserPermissionSets.length > 0 && val.endUserPermissionSets.some((permissionSet) =>  permissionNames.has(permissionSet.name) ) );
      
    }
 
    return filteredData;
  };

  const filteredColumnsData = getFilteredColumns();
  const filteredRowsData = getFilteredRows(filteredColumnsData);

  const rowHeight = 40;
  const totalHeightForTenRows = rowHeight * 10; // 400 pixels
 
  return (
    <div ref={ref} className="permission-matrix" style={{ textAlign: "right" }}>
      <Table
        loading={loading}
        columns={filteredRowsData.length > 0 && filteredColumnsData}
        size="small"
        dataSource={filteredColumnsData.length > 2 && filteredRowsData}
        bordered
        pagination={{ pageSize: 10, showSizeChanger: false }}
        style={{ width: "calc(100vw - 400px)",  overflowY: "visible" }}
        scroll={{ x: true, y: `${totalHeightForTenRows}px` }}
      />
    </div>
  );
};

export default DataTable;
