import { Alert, Button, Grid, LinearProgress, Snackbar, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { addNewPermissionSetCategory, fetchPermissionSetCategories, selectPermissionSetCategoryById, updatePermissionSetCategory } from "../../../redux/reducers/permissionSetCategorySlice";
import { fetchEndUserRoles } from "../../../redux/reducers/endUserRoleSlice";
import { fetchFunctionalPermissionSets} from "../../../redux/reducers/functionalPermissionSetSlice";

const PermissionSetCategoryUpdate = () => {
    const { categoryId, action } = useParams();
    const [loading, setLoading] = useState(false);
    const [permissionData, setPermissionData] = useState({});
    const [snakebarOps, setSnakebarOps] = useState({ open: false, severity: "", message: "" });
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const permissionSetCategoryStatus = useSelector(state => state.permissionSetCategories.status);
    const updatedCategory = useSelector(state => selectPermissionSetCategoryById(state, categoryId))

   

    useEffect(() => {
        if (permissionSetCategoryStatus === 'idle') {
            dispatch(fetchPermissionSetCategories());
        }
        
        if (action === 'duplicate' && updatedCategory) {
            const data = {
                ...updatedCategory,
                name: updatedCategory.name + '_COPY'
            }
            setPermissionData(data)
        } else if (categoryId && categoryId !== 'new') {
            setPermissionData(updatedCategory);
        }
    }, [categoryId, updatedCategory]);

    const handleChange = (field, value) => {
        const updatedValue = {};
        updatedValue[field] = value;

        switch (field) {
            case 'name':
                setPermissionData({
                    ...permissionData,
                    ...updatedValue
                })
                return;
            case 'description':
                setPermissionData({
                    ...permissionData,
                    ...updatedValue
                })
                return;
            default:
                console.log("No case defined");
                return;
        }
    }

    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
    };

    const onSubmit = () => {
        return categoryId === "new"
            ? createPermissionSet()
            : action === "duplicate"
            ? createPermissionSet()
            : updatePermissionSet()
    }

    const createPermissionSet = async () => {
        setLoading(true);
        const newPermission = [{
            "name": permissionData.name,
            "description": permissionData.description
        }]
 
        dispatch(addNewPermissionSetCategory({data: newPermission, showStatus: setSnakebarOps})).then(response => {
            setLoading(false);
            if (response.type === "permissionSetCategory/addNewPermissionSetCategory/fulfilled") {
                navigate(-1)
            } 
        })
    }

    const updatePermissionSet = async () => {
        setLoading(true);
 
        const updatedPermission = [{
            "name": permissionData.name,
            "description": permissionData.description,
            "objkey": permissionData.objkey
        }]
  
        dispatch(updatePermissionSetCategory({data: updatedPermission, showStatus: setSnakebarOps})).then(response => {
            dispatch(fetchEndUserRoles());
            dispatch(fetchFunctionalPermissionSets());
            setLoading(false);
            if (response.type === "permissionSetCategory/updatePermissionSetCategory/fulfilled") {
                navigate(-1)
            }
        }) 
    }
    
    return (
        <div>
            <Snackbar open={snakebarOps.open} anchorOrigin={{ vertical: 'top', horizontal :'right' }} severity={snakebarOps.severity} autoHideDuration={10000} onClose={() => setSnakebarOps({ open: false })}>
                <Alert onClose={() => setSnakebarOps({ open: false })} severity={snakebarOps.severity} sx={{ width: '100%' }}>
                    {snakebarOps.message}
                </Alert>
            </Snackbar>
            {loading ? <LinearProgress sx={{ m: 50 }} color="inherit" /> :
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "left" }}>
                        <h2 className="page-title">{categoryId === "new" ? "Create New" : action === "duplicate" ? "Duplicate" : "Edit"} Permission Set Category</h2>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12} gutter={4}>
                        <Stack spacing={2}>
                            <TextField
                                value={permissionData && permissionData.name}
                                fullWidth
                                size="small"
                                label=""
                                //disabled={categoryId !== "new" && action !== "duplicate"}
                                name="Name"
                                placeholder="Permission Category Name"
                                onChange={(e) => handleChange("name", e.target.value)}
                                onInput={toInputUppercase}
                            />
                            <TextField
                                value={permissionData && permissionData.description}
                                fullWidth
                                size="small"
                                label=""
                                name="description"
                                placeholder="Category Description"
                                rows={4}
                                onChange={(e) => handleChange("description", e.target.value)}
                            />
                            <Stack direction='row' spacing={2}>
                                <Button
                                    variant="outlined"
                                    color="warning"
                                    onClick={() => navigate(-1)}
                                    sx={{ flex: 1 }}
                                    disableElevation
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{ flex: 1 }}
                                    disableElevation
                                    onClick={() => onSubmit()}
                                >
                                    Save
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>}
        </div>
    )
}

export default PermissionSetCategoryUpdate;