import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";
import _, { map } from "underscore";
import { API_CONFIGURATIONS } from "../apiConfigurations";
const { Map, default: Immutable } = require("immutable");

let baseUrl =
  API_CONFIGURATIONS.GET_PARENT_NAVIGATOR_TO_FUNCTIONAL_PERMISSION_SETS;
const updateUrl =
  API_CONFIGURATIONS.UPDATE_NAVIGATOR_TO_FUNCTIONAL_PERMISSION_SETS;
const bulkUpdateUrl =
  API_CONFIGURATIONS.BULK_UPDATE_NAVIGATOR_TO_FUNCTIONAL_PERMISSION_SETS;
const apiEndpoint = baseUrl;
const navUrl =
  API_CONFIGURATIONS.GET_PARENT_HIERARCHY_NAVIGATOR_TO_FUNCTIONAL_PERMISSION_SETS;
const eurbUrl =
  API_CONFIGURATIONS.GET_PARENT_HIERARCHY_NAVIGATOR_TO_END_USER_ROLES;
const oeurbUrl =
  API_CONFIGURATIONS.GET_OMITED_HIERARCHY_NAVIGATOR_TO_END_USER_ROLES;
const onavUrl =
  API_CONFIGURATIONS.GET_OMITED_HIERARCHY_NAVIGATOR_TO_FUNCTIONAL_PERMISSION_SETS;
const accessToken = localStorage.getItem("userToken");

let config = {
  mode: "no-cors",
  headers: {
    "Access-Control-Allow-Origin": "http://localhost:3000",
    xAccessToken: accessToken,
  },
};

export const initialState = {
  navigatorToFunctionalPermissionSetsMulti: [],
  status: "idle",
  error: null,
};

function pathForUpdate(ipath) {
  var path = ipath.toJS();

  var keys = _.range(path.length).map(() => "type");

  return _.flatten(_.zip(path, keys));
}

export const fetchNavigatorToFunctionalPermissionSets = createAsyncThunk("navigatorToFunctionalPermissionSets/fetchNavigatorToFunctionalPermissionSets",
  async () => {
  
    const grantIdForFetch = JSON.parse(localStorage.getItem("grantIdForFetch"));
    const grantNodeId = grantIdForFetch?.grantNodeId; 
    const url = `${apiEndpoint + "-2"}&grantNodeId=${grantNodeId}`;

    const response = await axios.get(url, config);

    localStorage.removeItem("grantIdForFetch");
    
    return response.data;
  })

export const fetchMultiChildNodes = createAsyncThunk("navigatorToFunctionalPermissionSets/fetchMultiChildNodes",async ({ expanded, record }) => {
    const childUrl = apiEndpoint + record.id;
    // const childUrl = 'http://localhost:44352/childNodes';
    const response = await axios.get(childUrl, config);
    ////debugger
    return { data: response.data, expanded: expanded, record: record };
  }
);

export const fetchNavigatorHierarchy = createAsyncThunk(
  "navigatorToFunctionalPermissionSets/fetchNavigatorHierarchy",
  async (record) => {
    const recordAsNumber = parseInt(record, 10);

    const childUrl = navUrl + "-2";
    // //debugger
    const response = await axios.get(childUrl, config);
    // //debugger
    return response.data;
  }
);

export const fetchOmitedNavigatorHierarchy = createAsyncThunk(
  "navigatorToFunctionalPermissionSets/fetchOmitedNavigatorHierarchy",
  async (record) => {
    const childUrl = onavUrl + "?name=" + "-2";
    // //debugger
    const response = await axios.get(childUrl, config);
    // //debugger
    return response.data;
  }
);

export const fetchNavigatorEndUserBreakdownHierarchy = createAsyncThunk(
  "navigatorToFunctionalPermissionSets/fetchNavigatorEndUserBreakdownHierarchy",
  async (record) => {
    try {
      const childUrl = eurbUrl + "?name=" + record.join(",");
      const response = await axios.get(childUrl, config);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const fetchOmitedNavigatorEndUserBreakdownHierarchy = createAsyncThunk(
  "navigatorToFunctionalPermissionSets/fetchOmitedNavigatorEndUserBreakdownHierarchy",
  async (record) => {
    try {
      const childUrl = oeurbUrl + "?name=" + record.join(",");
      const response = await axios.get(childUrl, config);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const updateNavigatorToFunctionalPermissionSets = createAsyncThunk("navigatorToFunctionalPermissionSets/update",async ({ request, onCloseUpdateDialog }) => {
    const response = await axios.post(updateUrl, request, config);
    // //debugger
    onCloseUpdateDialog();
  }
);

export const updateBulkNavigatorToFunctionalPermissionSets = createAsyncThunk(
  "navigatorToFunctionalPermissionSets/updateBulkNavigatorToFunctionalPermissionSets",
  async ({ request, onCloseUpdateDialog }) => {
    ////debugger;
    const response = await axios.post(bulkUpdateUrl, request, config);
    localStorage.setItem("updatedGrant", JSON.stringify(true));
    // //debugger
    onCloseUpdateDialog();
  }
);

const navigatorToFunctionalPermissionSetsMultiSlice = createSlice({
  name: "navigatorToFunctionalPermissionSetsMulti",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        fetchNavigatorToFunctionalPermissionSets.pending,
        (state, action) => {
          state.status = "loading";
        }
      )
      .addCase(
        fetchNavigatorToFunctionalPermissionSets.fulfilled,
        (state, action) => {
          state.status = "succeeded";
          const data = dataFormatter(action.payload);
          console.log("case sate is", state);
          console.log("case action is", action);

          state.navigatorToFunctionalPermissionSetsMulti = state.navigatorToFunctionalPermissionSetsMulti.concat(data);
          ////debugger
          console.log("state nav set is",state.navigatorToFunctionalPermissionSetsMulti);
          localStorage.setItem("parentList", JSON.stringify(true));
        }
      )
      .addCase(
        fetchNavigatorToFunctionalPermissionSets.rejected,
        (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
        }
      )
      .addCase(fetchMultiChildNodes.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchMultiChildNodes.fulfilled, (state, action) => {
        const { data, expanded, record } = action.payload;
        state.status = "succeeded";
        console.log("RECORD ", record);
        var path = pathForUpdate(Immutable.fromJS(record.path));

        var permissionSetToUpdate = Immutable.fromJS(state.navigatorToFunctionalPermissionSetsMulti);
        console.log("PATH ", path);
        var updatedPermissionSet = permissionSetToUpdate.updateIn(path, (node) => {
            console.log("NODE ", node, data);
            return dataFormatter(data, record.path);
          }).toJS();

        // if (expand) {
        //     const path = record && record.path;
        //     console.log("Action", action)
        //     const expandedIndex = stateCopy.findIndex(navigatorEntry => navigatorEntry.id === path[0]);

        //     stateCopy[expandedIndex] = {...stateCopy[expandedIndex], type: dataFormatter(action.payload.data)}
        // }
        console.log("Updated ", updatedPermissionSet);
        state.navigatorToFunctionalPermissionSetsMulti = updatedPermissionSet;
        console.log("state nav new set is",state.navigatorToFunctionalPermissionSetsMulti );
        localStorage.setItem("childList", JSON.stringify(true));
      })
      .addCase(fetchMultiChildNodes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchNavigatorHierarchy.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchNavigatorHierarchy.fulfilled, (state, action) => {
        state.status = "succeeded";
        // const data = dataFormatter(action.payload);
        // state.navigatorToFunctionalPermissionSetsMulti = state.navigatorToFunctionalPermissionSetsMulti.concat(data)
        ////debugger
      })
      .addCase(fetchNavigatorHierarchy.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchOmitedNavigatorHierarchy.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchOmitedNavigatorHierarchy.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(fetchOmitedNavigatorHierarchy.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(
        fetchNavigatorEndUserBreakdownHierarchy.pending,
        (state, action) => {
          state.status = "loading";
        }
      )
      .addCase(
        fetchNavigatorEndUserBreakdownHierarchy.fulfilled,
        (state, action) => {
          state.status = "succeeded";
        }
      )
      .addCase(
        fetchNavigatorEndUserBreakdownHierarchy.rejected,
        (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
        }
      )
      .addCase(
        updateNavigatorToFunctionalPermissionSets.pending,
        (state, action) => {
          state.status = "loading";
        }
      )
      .addCase(
        updateNavigatorToFunctionalPermissionSets.fulfilled,
        (state, action) => {
          state.status = "succeeded";
          localStorage.setItem("updatedGrant", JSON.stringify(true));
        }
      )
      .addCase(
        updateNavigatorToFunctionalPermissionSets.rejected,
        (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
        }
      )
      .addCase(
        updateBulkNavigatorToFunctionalPermissionSets.pending,
        (state, action) => {
          state.status = "loading";
        }
      )
      .addCase(
        updateBulkNavigatorToFunctionalPermissionSets.fulfilled,
        (state, action) => {
          state.status = "succeeded";
        }
      )
      .addCase(
        updateBulkNavigatorToFunctionalPermissionSets.rejected,
        (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
        }
      );
  },
});

const dataFormatter = (permissions, path) => {
  const formattedPermissions = [];
  permissions.forEach((permission, idx) => {
     
    formattedPermissions.push({
      id: permission.id,
      label: permission.label,
      projection: permission.projection,
      type: permission.type,
      roles: permission.roles,
      consolidatedNodes: permission.consolidatedNodes,
      child: permission.child,
      expanded: false,
      path: path ? [...path, idx] : [idx],
      state: permission.state,
      parentId: permission.parentId,
    });
  });

  return formattedPermissions;
};

const searchTreeStructure = (path, data) => {
  const indexList = [];
  data.findIndex((entry) => entry.id === path[0]);
};

export default navigatorToFunctionalPermissionSetsMultiSlice.reducer;

export const selectAllNavigatorToFunctionalPermissionSets = (state) =>
  state.navigatorToFunctionalPermissionSetsMulti
    .navigatorToFunctionalPermissionSetsMulti;
