import * as React from "react";
import {
  Divider,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Toolbar,
  Typography,
  Tooltip,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import HailIcon from "@mui/icons-material/Hail";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import routes from "../../routes";
import { renderRoutes } from "../../routes";
import SignIn from "../../pages/SignIn";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/reducers/authUserSlice";
import {
  closeActionStatus,
  selectActionStatus,
} from "../../redux/reducers/actionStatusSlice";
import ActionStatusDialog from "../../components/ActionStatusDialog";
import logo from "../../static/images/logo-colored-white.png";
const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#323a46",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AppLayout = () => {
  const [open, setOpen] = React.useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const { userInfo, userToken } = useSelector((state) => state.authUser);
  const actionStatus = useSelector((state) => selectActionStatus(state));

  const applicationUrl = localStorage.getItem("applicationUrl")
    ? localStorage.getItem("applicationUrl")
    : null;

  const appVersion = localStorage.getItem("appVersion")
    ? localStorage.getItem("appVersion")
    : null;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch({ type: "USER_LOGOUT" });
    console.log("done 2");
  };

  const renderNavItems = () => {
    const items = [];
    routes.forEach((item, index) => {
      const itemContent = (
        <ListItemButton disabled={item.disabled} key={item.id}>
          <ListItemIcon>
            {index % 2 === 0 ? (
              <PeopleAltIcon sx={{ color: "#ffffff" }} />
            ) : (
              <HailIcon sx={{ color: "#ffffff" }} />
            )}
          </ListItemIcon>
          <ListItemText sx={{ color: "#ffffff" }} primary={item.title} />
        </ListItemButton>
      );
      items.push(
        <ListItem
          disablePadding
          key={item.id}
          selected={location.pathname.split("/").includes(item.target)}
        >
          {item.disabled ? (
            <span className="custom-link">{itemContent}</span>
          ) : (
            <Link className="custom-link" to={item.target}>
              {itemContent}
            </Link>
          )}
        </ListItem>
      );
    });
    return items;
  };

  const onCloseActionStatus = () => {
    dispatch(closeActionStatus());
  };

  return location.pathname === "/sign-in" ? (
    <SignIn />
  ) : (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <ActionStatusDialog
        actionStatus={actionStatus}
        handleClose={onCloseActionStatus}
      />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {!open && (
            // <Typography
            //   variant="h6"
            //   noWrap
            //   component="div"
            //   sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
            // >
            //   IFS Security Expert
            // </Typography>
            <>
              <img src={logo} height="30px"></img>
            </>
          )}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => console.log("Nav Clicked")}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            IFS Security Expert
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}></Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>
          <Box sx={{ flexGrow: 0, marginRight: 1 }}>
            <Button
              onClick={() => handleLogout()}
              variant="contained"
              startIcon={<LogoutIcon />}
            >
              Logout
            </Button>
          </Box>
          {/* <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton
                onClick={() => console.log("Clicked Menu")}
                sx={{ p: 0 }}
              >
                <Avatar
                  alt="Remy Sharp"
                  src="https://image.freepik.com/free-photo/medium-shot-happy-man-smiling_23-2148221808.jpg"
                />
              </IconButton>
            </Tooltip>
          </Box> */}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        classes={{ paper: "custom-drawer-paper" }}
        PaperProps={{ sx: { whiteSpace: open ? "pre-wrap" : "no-wrap" } }}
      >
        <DrawerHeader>
          {open && (
            // <Typography
            //   variant="h6"
            //   noWrap
            //   component="div"
            //   sx={{
            //     display: {
            //       marginLeft: 10,
            //       xs: "none",
            //       md: "flex",
            //       color: "#ffffff",
            //     },
            //   }}
            // >
            //   IFS Security Expert
            // </Typography>

            <>
              <Box sx={{ pl: "1px" }}>
                {"  "}
                <img src={logo} height="30px"></img>
              </Box>
            </>
          )}
          <IconButton onClick={handleDrawerClose} sx={{ color: "#ffffff" }}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>{renderNavItems()}</List>
        {/* "IFS Version" and "applicationUrl" information */}
        {open && (
          <Box
            sx={{
              // position: "fixed",
              position: "relative",
              marginTop: "auto", // Pushes the content to the bottom              // display: { xs: "flex" },
              // bottom: "0",
              bottom: "20px",
              left: "20px",
              color: "#ffffff",
              // flexGrow: 1,
            }}
          >
            <Typography sx={{ textAlign: "left", fontSize: "small" }}>
              <b>IFS Version:</b> {appVersion}
            </Typography>
            <Typography
              sx={{ textAlign: "left", left: "20px", fontSize: "small" }}
            >
              <b>{applicationUrl}</b>
            </Typography>
          </Box>
        )}
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {renderRoutes()}
      </Box>
    </Box>
  );
};

export default AppLayout;
