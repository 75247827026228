import { Stack, IconButton, Grid } from "@mui/material";
import { ModeEdit, ContentCopy, Delete } from '@mui/icons-material';
import DataTable from "./DataTable";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import { useSelector, useDispatch } from 'react-redux'
import { selectAllFunctionalPermissionSets, fetchFunctionalPermissionSets, deleteFunctionalPermissionSet,fetchAssignEndUserRoles } from "../../../redux/reducers/functionalPermissionSetSlice";
import Swal from 'sweetalert2';

const PermissionView = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const functionalPermissionSets = useSelector(selectAllFunctionalPermissionSets);
    const functionalPermissionSetStatus = useSelector(state => state.functionalPermissionSets.status);
    const [deleteActive, setDeleteActive] = useState();

     useEffect(() => {
       const currentPath = location.pathname;
       localStorage.setItem("previousPath", currentPath);
     }, [location.pathname]);

    useEffect(() => {
        if (functionalPermissionSetStatus === 'idle') {
            dispatch(fetchFunctionalPermissionSets())
        }
    }, [functionalPermissionSetStatus, dispatch])

    const deletePermission = (id) => {
        
        Swal.fire({
            title: 'Delete Functional Permission Set',
            text: 'Are you sure you want to delete this Functional Permission Set?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                onDeleteConfirm(id);
            }
        });
    }

const onDeleteConfirm = async (id) => {
    try { 
        const endUserRolesAction = dispatch(fetchAssignEndUserRoles(id));
        const endUserRolesAssigned  = await endUserRolesAction; 
        if (endUserRolesAssigned?.payload === true) { 
           const result = await Swal.fire({
             title: "Delete Functional Permission Set",
             text: "The Functional Permission Set has already been assigned to one or more End-User Roles. Are you sure you want to delete this Functional Permission Set?",
             icon: "warning",
             iconColor: "red",
             showCancelButton: true,
             cancelButtonText: "CANCEL",
             confirmButtonText: "CONFIRM DELETION",
             confirmButtonColor: "red",
           });

            if (result.isConfirmed) {
                await dispatch(deleteFunctionalPermissionSet({ id }));
                Swal.fire('Deleted', 'The Functional Permission Set has been deleted.', 'success');
                dispatch(fetchFunctionalPermissionSets());
            } else {
                Swal.fire('Deletion Cancelled', 'The Functional Permission Set was not deleted.', 'info');
                dispatch(fetchFunctionalPermissionSets());
            }
        } else { 
                await dispatch(deleteFunctionalPermissionSet({ id }));
                Swal.fire('Deleted', 'The Functional Permission Set has been deleted.', 'success');
                dispatch(fetchFunctionalPermissionSets());   
        }
    } catch (error) {
        console.error('Error:', error);
        Swal.fire('Error', 'An error occurred while processing the request.', 'error');
        dispatch(fetchFunctionalPermissionSets());
    }
};



    const columns = [
        { field: 'name', headerName: 'Name', flex: 2 },
        { field: 'description', headerName: 'Description', flex: 3 },
        { field: 'category', headerName: 'Category', flex: 1 },
        { field: 'dateCreated', headerName: "Created Date", align: 'center', headerAlign: 'center', flex: 0.5, renderCell: (data) => moment(data.value).format('MM-DD-YYYY') },
        { field: 'lastModifiedDate', headerName: "Modified Date", align: 'center', headerAlign: 'center', flex: 0.5, renderCell: (data) => moment(data.value).format('MM-DD-YYYY') },
        {
            field: 'Actions', headerName: 'Actions', flex: 1, align: 'right', headerAlign: 'right', renderCell: (data) => {
                return (
                    <div>
                        <Stack direction='row' spacing={1}>
                            <Link className="custom-link" to={"/functional-permission-sets/duplicate/" + data.id}>
                                <IconButton color='primary' size="medium" sx={{ borderRadius: 2, border: '1px solid' }} variant="outlined"><ContentCopy sx={{ fontSize: 20 }} /></IconButton>
                            </Link>
                            <Link className="custom-link" to={"/functional-permission-sets/" + data.id}>
                                <IconButton color='warning' size="medium" sx={{ borderRadius: 2, border: '1px solid' }} variant="outlined"><ModeEdit sx={{ fontSize: 20 }} /></IconButton>
                            </Link>
                            <IconButton onClick={() => deletePermission(data.id)} color='error' size="medium" sx={{ borderRadius: 2, border: '1px solid' }}><Delete sx={{ fontSize: 20 }} /></IconButton>
                        </Stack>
                    </div>
                );
            }
        }
    ]

    return (
        <Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "left" }}>
                <h2 className="page-title">Functional Permission Sets</h2>
            </Grid>
            <DataTable columns={columns} data={functionalPermissionSets} loading={functionalPermissionSetStatus} />
        </Grid>
    )
}

export default PermissionView;
