import {
  Grid,
  TextField,
  Button,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  CircularProgress,
  LinearProgress,
  Typography,
  Card,
  Checkbox,
} from "@mui/material";
import {
  selectAllUsers,
  fetchUsers,
} from "../../../../redux/reducers/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Search, ExitToApp } from "@mui/icons-material";
import {
  fetchNavigatorToFunctionalPermissionSets,
  selectAllNavigatorToFunctionalPermissionSets,
  fetchNavigatorHierarchy,
  fetchOmitedNavigatorHierarchy,
} from "../../../../redux/reducers/navigatorToFunctionalPermissionSetsMultiSlice";
import {
  fetchFunctionalPermissionSets,
  selectAllFunctionalPermissionSets,
} from "../../../../redux/reducers/functionalPermissionSetSlice";
import ExcelJS from "exceljs";
var XLSX = require("xlsx");

const PermissionListView = () => {
  const dispatch = useDispatch();
  const navigatorToFunctionalPermissionSetsStatus = useSelector(
    (state) => state.navigatorToFunctionalPermissionSetsMulti.status
  );
  const navigatorToFunctionalPermissionSets = useSelector(
    selectAllNavigatorToFunctionalPermissionSets
  );
  const functionalPermissionSetStatus = useSelector(
    (state) => state.functionalPermissionSets.status
  );
  const functionalPermissionSets = useSelector(
    selectAllFunctionalPermissionSets
  );

  const [navigatorId, setNavigatorId] = useState("");
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
   const [omitNonGrantedAreas, setOmitNonGrantedAreas] = useState(false);
  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([
        dispatch(fetchNavigatorToFunctionalPermissionSets()),
        dispatch(fetchFunctionalPermissionSets()),
      ]);
      setLoading(false);
    };

    if (
      navigatorToFunctionalPermissionSetsStatus === "idle" ||
      functionalPermissionSetStatus === "idle"
    ) {
      fetchData();
    }
  }, [
    navigatorToFunctionalPermissionSetsStatus,
    functionalPermissionSetStatus,
    dispatch,
  ]);

  const toInputUppercase = (e) => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  const simulateAsyncOperation = async () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  };

  const getAllNavDataHierarchy = (record) => {
    setExporting(true);
    setProgressValue(10);
    if (omitNonGrantedAreas) {
          dispatch(fetchOmitedNavigatorHierarchy(record)).then(async (res) => {
            const navchildData = res.payload;

            let progress = 10;
            while (progress < 100) {
              await simulateAsyncOperation();
              progress += 10;
              setProgressValue(progress);
            }

            handleOnExport(navchildData);

            setProgressValue(100);
            setExporting(false);
          });
    }else{
          dispatch(fetchNavigatorHierarchy(record)).then(async (res) => {
            const navchildData = res.payload;

            let progress = 10;
            while (progress < 100) {
              await simulateAsyncOperation();
              progress += 10;
              setProgressValue(progress);
            }

            handleOnExport(navchildData);

            setProgressValue(100);
            setExporting(false);
          });
          }
    
  };

  //With all styles
  const handleOnExport = async (navData) => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("End-User Role Breakdown");

    const dataRows = [];

    const processItem = (item, level = 0) => {
      const row = [item.label];
      functionalPermissionSets.forEach((permissionSet) => {

        const state = item.state.find((stateItem) => stateItem.role === permissionSet.name);

        let grantType = "";
        
        if (state) {
          grantType = (state.stateColor === "F")  ? "RW"  : state.stateColor === "R"  ? "R" : "";
        }

        row.push(grantType);

      });

      dataRows.push({ level, row });

      if (item.child && item.child.length > 0) {
        item.child.forEach((childItem) => {
          processItem(childItem, level + 1);
        });
      }
    };

    navData.forEach((item) => {
      processItem(item);
    });

    const titleCell = sheet.getCell("A1");
    titleCell.value = "Permission Matrix - Navigator to Functional Permission Sets";
    titleCell.alignment = {
      horizontal: "left",
      vertical: "middle",
    };
    titleCell.font = {
      size: 10,
      bold: true,
      name: "Arial",
      color: { argb: "FF000000" },
    };

    const headerRow = sheet.addRow([ "Navigator Structure", ...functionalPermissionSets.map((set) => set.name)]);
    headerRow.height = 200;

    const firstHeaderCell = headerRow.getCell(1);
    firstHeaderCell.alignment = {
      vertical: "top",
      horizontal: "left",
    };
    firstHeaderCell.font = {
      bold: true,
    };
    firstHeaderCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFE0E0E0" },
    };

    for (let i = 2; i <= functionalPermissionSets.length + 1; i++) {
      const headerCell = headerRow.getCell(i);
      headerCell.alignment = {
        textRotation: 90,
        vertical: "bottom",
        horizontal: "center",
      };
      sheet.getColumn(i).width = 5;
      headerCell.font = {
        bold: false,
      };
    }

    dataRows.forEach(({ level, row }) => {
      const indentedRow = row.map((cell, index) => {
        if (index === 0) {
          return " ".repeat(level * 4) + cell;
        }
        return cell;
      });
      sheet.addRow(indentedRow);
    });

    for (let r = 3; r <= dataRows.length + 2; r++) {
      for (let c = 1; c <= functionalPermissionSets.length; c++) {
        const cellRef = sheet.getCell(r, c + 1);
        const grantType = dataRows[r - 3].row[c];

        if (grantType === "RW" || grantType === "R") {
          cellRef.value = grantType;
          cellRef.alignment = { horizontal: "center", vertical: "middle" };
          cellRef.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: grantType === "R" ? "FFADD8E6" : "FFC0C0C0" },
          };
          cellRef.border = {
            top: { style: "thin", color: { argb: "FF000000" } },
            bottom: { style: "thin", color: { argb: "FF000000" } },
            left: { style: "thin", color: { argb: "FF000000" } },
            right: { style: "thin", color: { argb: "FF000000" } },
          };
          cellRef.font = {
            color: { argb: "FF000000" },
          };
        }
      }
    }

    sheet.getColumn(1).width = 60;
 
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);

    // Trigger download
    const link = document.createElement("a");
    link.href = url;
    link.download = "Navigator_to_Functional_Permission_Sets.xlsx";
    link.click();
  };

  return (
    <Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "left" }}>
        <h2 className="page-title">
          Navigator to Functional Permission Sets Reports.
        </h2>
      </Grid>
      <br></br>
      <br></br>
      <Grid container spacing={2}> 
        <Grid item lg={4} sx={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            id="omitNonGrantedAreas"
            name="omitNonGrantedAreas"
            onChange={(e) => {
              setOmitNonGrantedAreas(e.target.checked);
            }}
          />
          <InputLabel sx={{ color: "black", textAlign: "left", marginLeft: 1 }}>
            Omit Non-Granted Areas
          </InputLabel>
        </Grid>
      </Grid>
      <br></br>
      <br></br>
      <Card sx={{ backgroundColor: "#D3D3D3", padding: "16px" }}>
        <Grid container spacing={2}>
          {/* <Grid item lg={4}>
                      <FormControl fullWidth size='small'>
                          <InputLabel id="func-cat-label">Reports by Navigator Entries</InputLabel>
                          <Select
                              //multiple
                              fullWidth
                              label="Reports by Navigator Entries"
                              labelId="func-cat-label"
                              input={<OutlinedInput label="Reports by Navigator Entries" />} 
                              style={{ textAlign: 'left' }}
                              onChange={(e) => {
                                setNavigatorId(e.target.value) 
                              }} 
                          >
                              {navigatorToFunctionalPermissionSets && navigatorToFunctionalPermissionSets.map((category) => (
                                  <MenuItem
                                      key={category.label}
                                      value={category.id}
                                  >
                                      {category.label}
                                  </MenuItem>
                              ))}
                          </Select>
                      </FormControl>
                  </Grid>  */}
          <Grid item lg={4} md={8} sm={12} xs={12}>
            <InputLabel sx={{ color: "black", textAlign: "left" }}>
              ✔ Navigator to Functional Permission Sets
            </InputLabel>
          </Grid>
          <Grid item lg={2} md={4} sm={12} xs={12} sx={{ textAlign: "right" }}>
            <Button
              fullWidth
              onClick={() => {
                getAllNavDataHierarchy();
              }}
              disableElevation
              variant="contained"
              startIcon={<ExitToApp />}
            >
              Export
            </Button>
          </Grid>
          <Grid item lg={6} md={4} sm={12} xs={12} sx={{ textAlign: "right" }}>
            {exporting && (
              <div style={{ width: "100%", marginTop: "20px", height: "20%" }}>
                <LinearProgress
                  variant="determinate"
                  value={progressValue > 100 ? 100 : progressValue}
                  sx={{ height: 8 }}
                />
                <Typography variant="body2">{`${parseInt(
                  progressValue > 100 ? 100 : progressValue + ""
                )}%`}</Typography>
              </div>
            )}
          </Grid>
        </Grid>
      </Card>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "40vh",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </Grid>
  );
};

export default PermissionListView;
